// useDexie.js
import { useState, useEffect } from 'react';
import { db, initializeDexieDb } from 'src/db';  // Import your Dexie db instance
import Dexie from 'dexie';

export default function useDexie(key, defaultValue) {
    const tableName = 'chatHistory';
    const [value, setValue] = useState(defaultValue);
    //const channel = new BroadcastChannel(`dexie-${tableName}-${key}`);

    useEffect(() => {
        if (!db) {
            initializeDexieDb();
        }
    }, [db]);
  
    useEffect(() => {
      const loadData = async () => {
        try {
            if (!db || !(db instanceof Dexie)){
                await initializeDexieDb();
            }
          if (db.tables.map(t => t.name).includes(tableName)) {
            const storedValue = await db.table(tableName).get(key);
            if (storedValue) {
              setValue(storedValue.data);
            }
          }
        } catch (error) {
          console.error('Error loading data from IndexedDB:', error);
        }
      };
  
      loadData();
  
      // Listen for changes from other tabs
      const handleMessage = (event) => {
        setValue(event.data);
      };
  
      //channel.addEventListener('message', handleMessage);
      return () => {
        //channel.removeEventListener('message', handleMessage);
        //channel.close();
      };
    }, [tableName, key]);
  
    const setValueInDexie = async (newValue) => {
      if (!db || !(db instanceof Dexie)){
        await initializeDexieDb();
      }

      setValue((currentValue) => {
        let processedValue;
  
        if (typeof newValue === 'function') {
          processedValue = newValue(currentValue);
        } else {
          processedValue = newValue;
        }
  
        const saveData = async () => {
          try {
            await db.table(tableName).put({
              id: key,
              data: processedValue
            });
            // Notify other tabs
            //channel.postMessage(processedValue);
          } catch (error) {
            console.error('Error saving to IndexedDB:', error);
          }
        };
  
        saveData();
        
        return processedValue;
      });
    };
  
    return [value, setValueInDexie];
  }