// src/utils/migrateLocalStorageToIndexedDB.js
import { db, initializeDexieDb } from 'src/db';

export const migrateLocalStorageToIndexedDB = async () => {
  // Check if migration has already been performed
  const migrationCompleted = localStorage.getItem('localStorageMigrationCompleted');
  if (migrationCompleted) {
    return;
  }

  try {
    console.log("Migrating data from localStorage to Dexie...");
    // Initialize Dexie if not already initialized
    await initializeDexieDb();

    // Get all keys from localStorage
    const localStorageKeys = Object.keys(localStorage);

    // Filter for relevant keys (you might want to adjust this based on your needs)
    const keysToMigrate = localStorageKeys.filter(key => 
      key.includes('chatHistory') || 
      key.includes('workflow') ||
      // Add other key patterns you want to migrate
      !key.includes('localStorageMigrationCompleted')
    );

    // Migrate each key-value pair
    for (const key of keysToMigrate) {
      try {
        const value = localStorage.getItem(key);
        if (value) {
          const parsedValue = JSON.parse(value);
          console.log({key})
          
          // Store in IndexedDB
          await db.table('chatHistory').put({
            id: key,
            data: parsedValue
          });

          // Optionally, remove from localStorage after successful migration
          //localStorage.removeItem(key);
        }
      } catch (error) {
        console.error(`Error migrating key ${key}:`, error);
      }
    }

    // Mark migration as completed
    localStorage.setItem('localStorageMigrationCompleted', 'true');
    
    console.log('Migration completed successfully');
  } catch (error) {
    console.error('Migration failed:', error);
    throw error;
  }
};