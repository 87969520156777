// src/sqlDb.js
import initSqlJs from 'sql.js';
import { db, defineSqlTable, initializeDexieDb } from './db';

let SQL;
let dbSql;

export const initializeSqlJs = async () => {
  await initializeDexieDb();
  if (!SQL) {
    SQL = await initSqlJs({
      locateFile: (file) => `/sql-wasm.wasm`, // Ensure this points correctly
    });
  }
  if (!dbSql) {
    dbSql = new SQL.Database();
  }
  // Call this function on page load
  await loadSqlJsDatabase('localDataAnalysis');
  return dbSql;
};

export const loadSqlJsDatabase = async (dbName) => {
  await defineSqlTable('sqlJsDatabases');
  const storedDb = await db.table('sqlJsDatabases').get(dbName);
  if (storedDb) {
    dbSql = new SQL.Database(storedDb.data); // Load the database from the binary data
  } else {
    dbSql = new SQL.Database(); // Create a new database if it doesn't exist
  }
};

const saveSqlJsDatabase = async (dbName) => {
  const binaryArray = dbSql.export(); // Export the database as a Uint8Array
  await db.table
  await db.table('sqlJsDatabases').put({ name: dbName, data: binaryArray });
};

export const executeSql = async (db, query) => {
  try {
    const result = db.exec(query);
    return result;
  } catch (error) {
    throw new Error('SQL Execution Failed: ' + error.message);
  }
};

// Function to create tables and insert data into sql.js DB
export const createTableAndInsertData = async (db, tableName, data) => {
  const columns = Object.keys(data[0]);
  const columnDefs = columns.map(col => `"${col}" TEXT`).join(', ');

  // Create table
  const createTableQuery = `CREATE TABLE IF NOT EXISTS "${tableName}" (${columnDefs});`;
  db.run(createTableQuery);

  // Prepare insert statement
  const placeholders = columns.map(() => '?').join(', ');
  const insertQuery = `INSERT INTO "${tableName}" (${columns.map(col => `"${col}"`).join(', ')}) VALUES (${placeholders});`;
  const stmt = db.prepare(insertQuery);

  // Insert each row in a transaction for performance
  db.run('BEGIN TRANSACTION;');
  data.forEach(row => {
    const values = columns.map(col => row[col]);
    stmt.run(values);
  });
  db.run('COMMIT;');
  stmt.free();

  // Save SQL.js database to IndexedDB
  await saveSqlJsDatabase('localDataAnalysis');
};