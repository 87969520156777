// src/db.js
import Dexie from 'dexie';

// Initialize Dexie Database
export let db = {};

export const initializeDexieDb = async () => {
  db = await new Dexie('Agent700Database');

  db.version(1).stores({
    chatHistory: 'id, data',
    sqlJsDatabases: 'name, data'
  });

  try {
    await db.open();
  } catch(e) {
    console.log("No local Agent700Database db found. Creating new database...")
  }
}

export const getExistingTableSchemas = () => {
  const existingTables = {};
  
  db.tables.forEach(table => {
    // Retrieve the primary key and indexes
    const primaryKey = table.schema.primKey.name; // Correctly access the primary key name
    const indexes = table.schema.indexes.map(index => index.keyPath || index.name);

    // Combine primary key and indexes into the schema string
    existingTables[table.name] = [primaryKey === 'id' ? '++id' : primaryKey, ...indexes].join(', ');
  });

  return existingTables;
};

// Function to define a new table
export const defineTable = async (tableName, columns) => {
  // Check if table already exists
  if (!db.tables.map(t => t.name).includes(tableName)) {
    // Define the schema for the new table
    let tableSchemas = getExistingTableSchemas();
    tableSchemas[tableName] = '++id, ' + columns.map(col => col.toLowerCase().replace(/ /g, '_')).join(', ');

    await db.close();

    // Define the new version including all previous tables and the new one
    db.version(db.verno + 1).stores({
      ...tableSchemas
    }).upgrade(() => {});

    try {
      await db.open();
      console.log(`Table "${tableName}" has been created successfully.`);
    } catch (error) {
      console.error('Failed to open the database after defining a new table:', error);
      throw error;
    }
  } else {
    console.log(`Table "${tableName}" already exists.`);
  }
};

export const defineSqlTable = async (tableName) => {
  // Check if table already exists
  if (!db.tables.map(t => t.name).includes(tableName)) {
    // Define the schema for the new table
    let tableSchemas = getExistingTableSchemas();
    tableSchemas[tableName] = 'name, data' // 'name' is the primary key, 'data' holds the binary data

    await db.close();

    // Define the new version including all previous tables and the new one
    db.version(db.verno + 1).stores({
      ...tableSchemas
    }).upgrade(() => {});

    try {
      await db.open();
      console.log(`Table "${tableName}" has been created successfully.`);
    } catch (error) {
      console.error('Failed to open the database after defining a new table:', error);
      throw error;
    }
  } else {
    console.log(`Table "${tableName}" already exists.`);
  }
};