// WorkflowDetails.js
import React, { useState, useEffect, useContext } from 'react';
import {
  IconButton,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Collapse,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { TextFieldStyled } from './WorkflowDetailsStyles';
import { useAgentContext } from 'src/contexts/AgentContext';
import { useWorkflowContext } from 'src/contexts/WorkflowContext';
import { useNavigationContext } from 'src/contexts/NavigationContext';
import { useAuth } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';

function WorkflowDetails() {
  const {
    selectedWorkflow,
    updateWorkflow,
    selectedWorkflowRevision,
    saveWorkflow,
    handleSelectPreviousRevision,
    handleSelectNextRevision,
    selectedWorkflowRevisionIndex,
    setSelectedWorkflowRevision,
  } = useWorkflowContext();

  const { openNavigationPane } = useNavigationContext();
  const { agents, setSelectedAgent, setSelectedAgentRevision } = useAgentContext();
  const { user } = useAuth();
  const { openSnackbar } = useContext(SnackbarContext);

  // Handler to change agent in the workflow
  const handleAgentChange = (index, agentId) => {
    const agentIds = [...selectedWorkflowRevision.agentIds || []];
    agentIds[index] = agentId;

    setSelectedWorkflowRevision((currentWorkflowRevision) => ({
      ...currentWorkflowRevision,
      agentIds,
    }));

    // Set the selected agent based on the first agent ID
    if (agentIds.length > 0 && agentIds[0] !== null) {
      const firstAgent = agents.find((agent) => agent.id === agentIds[0]);
      setSelectedAgent(firstAgent);
      setSelectedAgentRevision(firstAgent.revisions[firstAgent.revisions.length - 1]);
    }
  };

  // Handler to add a new agent to the workflow
  const addAgentToWorkflow = () => {
    const agentIds = [...selectedWorkflowRevision.agentIds, null];
    setSelectedWorkflowRevision((currentWorkflowRevision) => ({
      ...currentWorkflowRevision,
      agentIds,
    }));
  };

  // Handler to update workflow properties
  const handleUpdateWorkflow = (event, property) => {
    const value = event.target.value;
    const updatedWorkflowRevision = {
      ...selectedWorkflowRevision,
      [property]: value,
    };
    updateWorkflow(updatedWorkflowRevision);
  };

  // Handler to save the workflow
  const handleSaveWorkflow = () => {
    saveWorkflow(selectedWorkflow, selectedWorkflowRevision);
  };

  // State for share link toggles (commented out in your original code)
  const [isShowShareLinkChecked, setShowShareLinkChecked] = useState(false);
  const [isPublishExpanded, setPublishExpanded] = useState(false);

  return (
    <Box sx={{ width: '535px', maxWidth: '100%', padding: 2 }}>
      {/* Back Button */}
      <IconButton
        onClick={() => {
          openNavigationPane('workflowList');
        }}
        sx={{ marginBottom: 2 }}
      >
        <ArrowLeftIcon />
      </IconButton>
      <Typography variant="h6" sx={{ fontWeight: 600, backgroundColor: 'lightgrey', borderBottom: '1px solid black', padding: 1, marginBottom: 1 }}>
        Collaboration: {selectedWorkflowRevision.name}
      </Typography>

      {/* Revision Navigation */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          marginBottom: 2,
        }}
      >
        <IconButton onClick={handleSelectPreviousRevision} disabled={selectedWorkflowRevisionIndex === 0}>
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="body1">Revision #{selectedWorkflowRevision.id}</Typography>
        <IconButton onClick={handleSelectNextRevision} disabled={selectedWorkflowRevisionIndex === selectedWorkflow.revisions.length - 1}>
          <ArrowRightIcon />
        </IconButton>
      </Box>

      {/* Workflow Side Drawer */}
      <Box
        sx={{
          padding: 0,
          width: (theme) => theme.spacing(60),
          backgroundColor: '#fff',
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Workflow Name */}
        <TextFieldStyled
          label="Collaboration Name"
          variant="outlined"
          value={selectedWorkflowRevision.name}
          onChange={(event) => handleUpdateWorkflow(event, 'name')}
          onKeyPress={(event) => event.key === 'Enter' && handleUpdateWorkflow(event, 'name')}
          fullWidth
          sx={{ marginBottom: 2 }}
        />

        {/* Workflow Details Header */}
        <Typography variant="h5" gutterBottom>
        Collaboration Details
        </Typography>

        {/* Workflow Description */}
        <Typography
          variant="body1"
          sx={{
            backgroundColor: 'lightgrey',
            borderRadius: 1,
            padding: 2,
            marginBottom: 2,
          }}
        >
          Collaborations allow you to chain agents in sequence. An agent terminates and advances when JSON{" "}
          {'{ "agent700Terminate": true, ... "customKey": customData... }'} is in a response, then passes the JSON to the next agent.
        </Typography>
        <br />

        {/* Agent Selection */}
        {selectedWorkflowRevision.agentIds.map((agentId, index) => (
          <FormControl key={index} fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id={`agent-select-label-${index}`}>Select Agent</InputLabel>
            <Select
              labelId={`agent-select-label-${index}`}
              id={`agent-select-${index}`}
              value={agentId || ''}
              label="Select Agent"
              onChange={(e) => handleAgentChange(index, e.target.value)}
            >
              {agents.map((agent) => (
                <MenuItem key={agent.id} value={agent.id}>
                  {agent.revisions[agent.revisions.length - 1].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}

        {/* Add Agent Button */}
        <IconButton
          onClick={addAgentToWorkflow}
          sx={{
            width: '0.6in',
            height: '0.6in',
            marginBottom: 2,
            '& svg': {
              fontSize: '0.5in',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <AddIcon />
        </IconButton>

        {/* Save Button */}
        <Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveWorkflow}
            sx={{ padding: '10px 30px' }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default WorkflowDetails;