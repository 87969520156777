import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, List, ListItem, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { makeStyles } from "@mui/styles";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAgentContext } from "src/contexts/AgentContext";
import { useNavigationContext } from "src/contexts/NavigationContext";
import ClearIcon from '@mui/icons-material/Clear';
import { v4 as uuid } from 'uuid';
import { useStyles } from './AgentListStyles';
import { useWorkflowContext } from 'src/contexts/WorkflowContext';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { Box } from '@mui/material';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';

export default function AgentList() {
  const classes = useStyles();
  const {
    agents, addAgent, deleteAgent, defaultAgent, setSelectedAgent, setSelectedAgentRevision, setSelectedAgentRevisionIndex, saveAgentRevision, lastSavedAgentRevisions, setLastSavedAgentRevisions
  } = useAgentContext();
  const {
    setSelectedWorkflow, setSelectedWorkflowRevision
  } = useWorkflowContext();
  const { openNavigationPane } = useNavigationContext();
  const { selectedOrganization } = useOrganizationContext();

  // States for the confirmation dialog
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSharedAgents, setShowSharedAgents] = useState(false);

  // Open the confirmation dialog and store the agent ID
  const handleOpenDialog = (agentId) => {
    setAgentToDelete(agentId);
    setDialogOpen(true);
  };

  // Close the confirmation dialog and reset the agent ID
  const handleCloseDialog = () => {
    setAgentToDelete(null);
    setDialogOpen(false);
  };

  // Delete the agent and close the dialog
  const handleConfirmDelete = () => {
    if (agentToDelete) {
      deleteAgent(agentToDelete);
    }
    handleCloseDialog();
  };

  // Function to handle the search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the shared agents filter toggle
  const handleToggleSharedAgents = () => {
    setShowSharedAgents(!showSharedAgents);
  };

  // Filter agents based on the search term and shared agents filter
  const filteredAgents = agents ? agents.filter(agent =>
    agent.revisions[agent.revisions.length - 1]?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!showSharedAgents || agent.sharedWithUser || agent.sharedByUser)
  ) : [];

  const toggleSearch = () => {
    if (isSearchOpen) {
      setSearchTerm(''); // Clear search term when closing the search box
    }
    setSearchOpen(!isSearchOpen);
  };

  const handleAddNewAgent = () => {
    const newAgent = { ...defaultAgent, id: uuid() }
    addAgent(newAgent);
    handleSelectAgent(newAgent);

    saveAgentRevision(newAgent, { ...newAgent.revisions[0], organizationId: selectedOrganization });
  };

  const handleSelectAgent = (agent) => {
    setSelectedWorkflow(null);
    setSelectedWorkflowRevision(null);

    setSelectedAgent(agent)
    setSelectedAgentRevision(agent.revisions[agent.revisions.length - 1])
    setLastSavedAgentRevisions({ ...lastSavedAgentRevisions, [agent.id]: agent.revisions[agent.revisions.length - 1] })
    setSelectedAgentRevisionIndex(agent.revisions.length - 1)
    openNavigationPane('agentDetails')
  };

  //console.log('Agents: ', agents);

  if (!agents.length) {
    return (
      <div>
        <Typography variant="body1" style={{ padding: '1rem' }}>
          Loading agents. Click the <AddIcon /> button below to add a new agent.
        </Typography>
        <IconButton color="default" onClick={handleAddNewAgent} className={classes.addAgentButton}>
          <AddIcon />
        </IconButton>
      </div>
    );
  }
  return (
    <div>
     <Box display="flex" alignItems="center" justifyContent="space-between">
        <div>
          <IconButton color="default" onClick={handleAddNewAgent} className={classes.addAgentButton}>
            <AddIcon />
          </IconButton>
          <IconButton color="default" onClick={toggleSearch} className={classes.addAgentButton}>
            <SearchIcon />
          </IconButton>
          {isSearchOpen && (
            <InputBase
              placeholder="Search..."
              onChange={handleSearchChange}
              autoFocus
              style={{ marginLeft: 8, flex: 1 }}
            />
          )}
        </div>
        <IconButton
          color={showSharedAgents ? "primary" : "default"}
          onClick={handleToggleSharedAgents}
          className={classes.addAgentButton}
          style={{ marginRight: '4%' }}
        >
          <GroupIcon />
        </IconButton>
      </Box>
      <List>
      {filteredAgents.map((agent) => (
        <ListItem button key={agent.id} onClick={() => { handleSelectAgent(agent) }}>
          <ListItemText
            primary={agent.revisions[agent.revisions.length - 1].name}
          />
          {agent.sharedByUser ? <GroupAddIcon titleAccess={'Shared With Other Users'} sx={{color: 'rgb(99, 115, 129)'}} /> : null}

          <ListItemIcon>
            {agent.sharedWithUser ? <GroupIcon titleAccess={'Shared By Another User'} sx={{ marginLeft: 1 }} /> :
            <IconButton
              disabled={agent.sharedWithUser}
              sx={{
                '&.Mui-disabled': {
                  color: 'inherit', // Set the desired color for the disabled state
                },
              }}
              edge="end"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenDialog(agent.id);
              }}>
              <ClearIcon />
            </IconButton>}
          </ListItemIcon>
        </ListItem>
      ))}
      </List>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Agent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this agent?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
