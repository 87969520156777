// AlignmentDataContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import routes from 'src/config/routes';
import { useAuth } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';

export const AlignmentDataContext = createContext();

export const AlignmentDataProvider = ({ children }) => {
  const [alignmentData, setAlignmentData] = useState({});
  const { user, isLoggedIn } = useAuth();
  const { openSnackbar } = useContext(SnackbarContext);

  const alignmentDataKeyNamePattern = /^[-a-zA-Z0-9._]+$/;
  const alignmentDataKeyNameAntiPattern = /[^-a-zA-Z0-9._]/g;

  const fetchAlignmentData = async () => {
    if (!user) return;
    try {
      const response = await axios.get(routes.alignmentData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (response.data) {
        // Assuming response.data is an array of { key, value }
        const alignmentDataObject = response.data.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});

        // Optional: Sort the keys if you have a timestamp or other criteria
        setAlignmentData(alignmentDataObject);
      }
    } catch (error) {
      console.error('Failed to fetch alignment data:', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchAlignmentData();
    } else {
      setAlignmentData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const isValidVariableName = (key) => {
    return alignmentDataKeyNamePattern.test(key);
  };

  // Add or update alignment data on the server
  const addOrUpdateAlignmentData = async (key, value, oldKey = null) => {
    if (!isValidVariableName(key)) {
      openSnackbar(
        'Invalid context name. Only letters, numbers, periods, dashes, and underscores are allowed.',
        'error'
      );
      return 'Invalid context name. Only letters, numbers, periods, dashes, and underscores are allowed.';
    }

    try {
      if (oldKey) {
        // We're updating an existing variable, possibly with a new key name
        const data = { key, value, oldKey };

        const response = await axios.put(routes.alignmentData, data, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        });
        if (response.data) {
          openSnackbar('Alignment data updated!', 'success');
          setAlignmentData((prevData) => {
            const newData = { ...prevData };
            // Remove old key + value
            delete newData[oldKey];
            
            // Insert the new/updated key at the beginning
            return { [key]: value, ...newData };
          });
          return true;
        }
      } else {
        // We're adding a new variable
        const data = { key, value };

        const response = await axios.post(routes.alignmentData, data, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        });
        if (response.data) {
          openSnackbar('Alignment data saved!', 'success');
          setAlignmentData((prevData) => {
            // Insert the new key at the beginning
            return { [key]: value, ...prevData };
          });
          return true;
        }
      }
    } catch (error) {
      console.error('Failed to add/update alignment data:', error);
      const errorMessage = error.response?.data?.message || 'Error saving alignment data.';
      // Do not open the snackbar here since it's being handled in the component
      return errorMessage;
    }
  };

  // Delete alignment data item
  const deleteAlignmentDataItem = async (key) => {
    try {
      const response = await axios.delete(`${routes.alignmentData}/${encodeURIComponent(key)}`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      if (response.status === 200 || response.status === 204) {
        openSnackbar('Alignment data deleted!', 'success');
        setAlignmentData((prevData) => {
          const newData = { ...prevData };
          delete newData[key];
          return newData;
        });
        return true; // Indicate success
      } else {
        openSnackbar('Error deleting alignment data.', 'error');
        return false; // Indicate failure
      }
    } catch (error) {
      console.error('Failed to delete alignment data:', error);
      openSnackbar('Error deleting alignment data.', 'error');
      return false; // Indicate failure
    }
  };

  return (
    <AlignmentDataContext.Provider
      value={{
        alignmentData,
        addOrUpdateAlignmentData,
        deleteAlignmentDataItem,
        alignmentDataKeyNamePattern,
        alignmentDataKeyNameAntiPattern,
      }}
    >
      {children}
    </AlignmentDataContext.Provider>
  );
};