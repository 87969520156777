// ChatContext.js
import { useState, useContext, createContext, useEffect, useRef } from 'react';
import { MODELS, getFirstDefinedValue } from 'src/components/utils';
import axios from 'src/utils/axios';
import { v4 as uuid } from 'uuid';
import { useAgentContext } from './AgentContext';
import { useAuth } from './AuthContext';
import { useWorkflowContext } from './WorkflowContext';
import { parameterizeDataContextString, jsonToCSV, hasAgentRevisionChanged } from 'src/components/utils';
import routes from 'src/config/routes';
import useDexie from 'src/hooks/useDexie';
import SnackbarContext from 'src/contexts/SnackbarContext';
import io from 'socket.io-client';
import { useSqlContext } from 'src/contexts/SqlContext';
import { getExistingTableSchemas, db, initializeDexieDb } from 'src/db'; // Import the exported tableSchemas
import Dexie from 'dexie';


const ChatContext = createContext(null);

const isSharedAgent = window.location.pathname.startsWith('/share/agent/')

const ChatContextProvider = ({ children }) => {
  const { agents,
    selectedAgent,
    selectedAgentRevision,
    defaultAgentRevision,
    setSelectedAgent,
    setSelectedAgentRevision,
    urlProvidedRevisionId,
    localAnalysisEnabled
  } = useAgentContext();
  const { selectedWorkflow, selectedWorkflowRevision } = useWorkflowContext();
  const [prompt, setPrompt] = useState('');
  const [chatHistory, setChatHistory] = useDexie(selectedWorkflow ? `workflow-${selectedWorkflow?.id || 'default'}-chatHistory` : `agent-${selectedAgent?.id || 'default'}-chatHistory`, []);
  const [incomingRequest, setIncomingRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const chatHistoryRef = useRef(chatHistory);
  const selectedAgentRevisionRef = useRef(selectedAgentRevision);
  const { openSnackbar } = useContext(SnackbarContext);
  const { user, isLoggedIn, logout } = useAuth();

  const { runQuery } = useSqlContext(); // Access the runQuery function
  const bufferedResponseRef = useRef(''); // Initialize as empty string

  const [isConnected, setIsConnected] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const maxReconnectAttempts = 20;

  // Update refs with the current values in each render
  useEffect(() => {
    chatHistoryRef.current = chatHistory;
    selectedAgentRevisionRef.current = selectedAgentRevision;
  });

  useEffect(() => {
    const loadChatHistory = async () => {
      try {
          let key;
          if (selectedAgent && selectedAgent.id) {
            key = `agent-${selectedAgent.id}-chatHistory`;
          } else if (selectedWorkflow && selectedWorkflow.id) {
            key = `workflow-${selectedWorkflow.id}-chatHistory`;
          } else {
            console.warn('No valid selectedAgent or selectedWorkflow found.');
            setChatHistory([]); // Reset chat history if there's no valid key
            return;
          }
          
          setChatHistory([]);  // Reset while loading
          if (!db || !(db instanceof Dexie)){
              await initializeDexieDb();
          }
          const storedHistory = await db.table('chatHistory').get(key);
          if (storedHistory) {
            setChatHistory(storedHistory.data || []);
          }
          
          // Add introductory text after loading the history
          addIntroductoryText();
      } catch (error) {
        console.error('Error loading chat history from IndexedDB:', error);
        setChatHistory([]); // Fallback to empty array if there's an error
      }
    };
  
    loadChatHistory();
  }, [selectedAgent, selectedWorkflow]);

  // Function to add or update the last chat items based on their types
  const upsertChatItem = (existingChatHistory, type, role, content) => {
    let updatedChatHistory = [...existingChatHistory];
    const lastIndex = updatedChatHistory.length - 1;
    const secondLastIndex = lastIndex - 1;

    // Check if the last and second last messages need to be flipped
    if (lastIndex >= 0 && secondLastIndex >= 0 &&
      updatedChatHistory[lastIndex].type === 'agentselected' &&
      updatedChatHistory[secondLastIndex].type === 'introductorytext') {
      // Flip the two messages
      [updatedChatHistory[lastIndex], updatedChatHistory[secondLastIndex]] =
        [updatedChatHistory[secondLastIndex], updatedChatHistory[lastIndex]];
    }

    // Update the last or second last message if they match the type
    if (lastIndex >= 0 && updatedChatHistory[lastIndex].type === type) {
      updatedChatHistory[lastIndex] = { ...updatedChatHistory[lastIndex], content };
    } else if (secondLastIndex >= 0 && updatedChatHistory[secondLastIndex].type === type) {
      updatedChatHistory[secondLastIndex] = { ...updatedChatHistory[secondLastIndex], content };
    } else {
      // Insert the new 'agentselected' message before 'introductorytext' if needed
      if (type === 'agentselected' && lastIndex >= 0 && updatedChatHistory[lastIndex].type === 'introductorytext') {
        updatedChatHistory.splice(lastIndex, 0, { id: uuid(), content, type, role });
      } else {
        // Append the new message at the end
        updatedChatHistory.push({ id: uuid(), content, type, role });
      }
    }

    return updatedChatHistory;
  };

  useEffect(() => {
    // Chat history cleared? Check for introductory text and inject if it exists
    if (!chatHistory || chatHistory.length === 0) {
      addIntroductoryText();
    }
  }, [chatHistory]);

  const previousAgentRevisionRef = useRef();

  const addAgentSelectedMessage = () => {
    // setChatHistory(existingChatHistory => existingChatHistory.concat(newChatItem));
    setChatHistory(existingChatHistory => {
      // Add or update agent changed message
      return upsertChatItem(
        existingChatHistory || [],
        'agentselected',
        '',
        `${selectedAgentRevision?.name || 'NO AGENT SELECTED (!)'} ${isLoggedIn ? '(' + (selectedAgentRevision?.model || '') + ')' : ''} selected on ${new Date().toLocaleString()}`
      );
    });
    addIntroductoryText();
  };

  const addIntroductoryText = () => {
    if (selectedAgentRevision?.introductoryText) {
      // Add or update introductory text
      setChatHistory(existingChatHistory => {
        return upsertChatItem(
          existingChatHistory || [],
          'introductorytext',
          'assistant',
          selectedAgentRevision.introductoryText
        );
      });
    }
  }

  useEffect(() => {
    if (!selectedAgent) return;

    const prevAgentRevision = previousAgentRevisionRef.current;
    previousAgentRevisionRef.current = selectedAgentRevision;

    // Add agent selected message only if it's the first time the agent is selected
    if (!prevAgentRevision || (prevAgentRevision && selectedAgentRevision
      && prevAgentRevision.agentId !== selectedAgentRevision.agentId)) {
      addAgentSelectedMessage(selectedAgentRevision?.name);
      if (loading) {
        stopChat();
      }
    } else if ((prevAgentRevision && selectedAgentRevision
      && prevAgentRevision.id === selectedAgentRevision.id
      && prevAgentRevision
      && hasAgentRevisionChanged(prevAgentRevision, selectedAgentRevision))
      || (!prevAgentRevision && selectedAgentRevision)
    ) {
      // If the agent revision has meaningfully changed, inject the "agentchanged" message
      setChatHistory(existingChatHistory => {
        // Add or update agent changed message
        return upsertChatItem(
          existingChatHistory || [],
          'agentchanged',
          '',
          `Agent updated on ${new Date().toLocaleString()} to ${selectedAgentRevision?.name || 'NO AGENT SELECTED (!)'} ${isLoggedIn ? '(' + (selectedAgentRevision?.model || '') + ')' : ''}`
        );
      });

      addIntroductoryText();
    }
  }, [selectedAgent, selectedAgentRevision]);

  const addErrorResponseToChatHistory = () => {
    const newChatItem = {
      id: uuid(),
      type: 'error',
      content: ''
    };

    setChatHistory(existingChatHistory => existingChatHistory.concat(newChatItem));
  };

  const addUserPromptToChatHistory = (prompt, images=[]) => {
    let newChatItem = {
      id: uuid(),
      role: 'user',
      content: prompt
    };
    if (images.length > 0) {
      newChatItem.content = [];
      newChatItem.content.push({type: 'text', text: prompt});
      images.forEach(i => newChatItem.content.push({type: 'image_url', image_url: {url: i.url}}));
    }

    setChatHistory(existingChatHistory => existingChatHistory.concat(newChatItem));
  };

  const addAIResponseToChatHistory = (response) => {
    const finishReason = response.finish_reason ?? '';
    const responseText = response.content ?? '';
    const citations = response.citations ?? '';

    const appendToLastAssistantMessage = (chatHistory, text) => {
      const lastIndex = chatHistory.length - 1;
      const updatedChatHistory = [...chatHistory];
      updatedChatHistory[lastIndex] = {
        ...updatedChatHistory[lastIndex],
        content: updatedChatHistory[lastIndex].content + text,
      };
      return updatedChatHistory;
    };

    const createNewChatItem = (role, type, content) => ({
      id: uuid(),
      role: role,
      type: type,
      content: content,
    });

    const addNewChatItem = (chatHistory, role, type, content) => {
      const newChatItem = createNewChatItem(role, type, content);
      return chatHistory.concat(newChatItem);
    };

    const formatCitations = (citations) => {
      if (!citations) return '';
      // Convert citations metadata to string format for storage
      return `<!--citations>${citations}<!--citations>`;
    }

    setChatHistory((existingChatHistory) => {
      const lastIndex = existingChatHistory.length - 1;
      const lastMessageIsAssistant = lastIndex >= 0 && existingChatHistory[lastIndex].role === 'assistant';

      if (lastMessageIsAssistant && !finishReason) {
        return appendToLastAssistantMessage(existingChatHistory, responseText);
      }

      if (finishReason === 'SAFETY') {
        return addNewChatItem(existingChatHistory, 'warning', 'content_warning', response.safety_ratings);
      }

      if (finishReason === 'MAX_TOKENS' || finishReason === 'length') {
        return addNewChatItem(existingChatHistory, 'warning', 'length_warning', selectedAgentRevision?.maxTokens);
      }

      if (responseText || !finishReason) {
        if (citations) {
          return addNewChatItem(existingChatHistory, 'assistant', 'success', formatCitations(citations));
        }
        else {
          return addNewChatItem(existingChatHistory, 'assistant', 'success', responseText);
        }
      }

      return existingChatHistory; // Default return in case no condition is met
    });
  };


  // Function to retrieve tables and columns
  const getTablesAndColumns = () => {
    return Object.entries(getExistingTableSchemas()).map(([table, columns]) => ({
      table,
      columns,
    }));
  };

  // Function to format tables and columns for AI
  const formatTablesInfo = () => {
    const tables = getTablesAndColumns();
    if (tables.length === 0) return '';

    const formattedTables = tables.map(table => {
      const escapedTableName = `\`${table.table}\``; // Escape table names
      const escapedColumns = table.columns.map(col => `\`${col}\``).join(', '); // Escape column names
      return `Table ${escapedTableName}: ${escapedColumns}`;
    }).join('\n');

    // Format as a SQL code block for better AI parsing
    return `Available tables and columns for local data analysis:\n\`\`\`sql\n${formattedTables}\n\`\`\``;
  };


  // Function to process the full AI response
  const processFullResponseForSQL = async (responseContent) => {
    // Parse the response to find SQL queries
    const sqlQueries = extractSQLFromResponse(responseContent);

    for (const sqlQuery of sqlQueries) {
      try {
        const result = await runQuery(sqlQuery);
        const formattedResult = formatSqlResult(result);
        addDataTableToChatHistory(formattedResult, 'assistant');
      } catch (error) {
        console.error('SQL Execution Error:', error);
        addAIResponseToChatHistory({
          content: `Error executing SQL query: ${error.message}`,
          role: 'assistant',
          type: 'error',
        });
      }
    }
  };


  // Utility function to extract SQL queries from the response
  const extractSQLFromResponse = (response) => {
    const sqlRegex = /```sql([\s\S]*?)```/gi;
    let match;
    const sqlQueries = [];

    while ((match = sqlRegex.exec(response)) !== null) {
      const sqlQuery = match[1].trim();
      if (sqlQuery) {
        sqlQueries.push(sqlQuery);
      }
    }

    return sqlQueries;
  };


  // Helper function to format SQL results for the datatable
  const formatSqlResult = (sqlResult) => {
    if (!sqlResult || sqlResult.length === 0) return [];

    const formattedData = [];

    sqlResult.forEach(result => {
      const { columns, values } = result;
      values.forEach(row => {
        const rowObject = {};
        columns.forEach((col, index) => {
          rowObject[col.toLowerCase().replace(/ /g, '_')] = row[index];
        });
        formattedData.push(rowObject);
      });
    });

    return formattedData;
  };

  const addDataTableToChatHistory = (dataTable, role) => {
    setChatHistory(existingChatHistory => {
      const newChatItem = {
        id: uuid(),
        role,
        type: 'datatable',
        content: dataTable,
      };
      return existingChatHistory.concat(newChatItem);
    });
  };

  const setLoadingComment = () => {
    const newChatItem = {
      id: uuid(),
      role: 'system',
      type: 'loading',
      content: ''
    };

    setChatHistory(existingChatHistory => existingChatHistory.concat(newChatItem));
    //console.log([ ...chatHistory, newChatItem ]);
  };

  const removeLastComment = () => {
    setChatHistory(existingChatHistory => existingChatHistory.slice(0, -1));
  };

  const removeLoadingComments = () => {
    setChatHistory(existingChatHistory => existingChatHistory.filter(chatItem => chatItem.type !== 'loading'));
  }

  const removePreviousDataTables = () => {
    setChatHistory(prevHistory => 
      prevHistory.filter(item => 
        !(item.type === 'datatable')
      )
    );
  };

  const insertScrubbedMessage = (scrubbedMessage) => {
    const newChatItem = {
      id: uuid(),
      type: 'scrubbed',
      content: scrubbedMessage
    };
    setChatHistory(existingChatHistory => existingChatHistory.concat(newChatItem));
  };

  const clearChatHistory = () => {
    const firstAgentId = selectedWorkflowRevision?.agentIds?.length > 0 ? selectedWorkflowRevision.agentIds[0] : null;
    const firstAgent = firstAgentId ? agents.find(agent => agent.id === firstAgentId) : null;
    const latestAgentRevision = firstAgent ? firstAgent.revisions[firstAgent.revisions.length - 1] : null;
    if (!!firstAgent) {
      setSelectedAgent(firstAgent);
      setSelectedAgentRevision(latestAgentRevision);
    }
    setChatHistory([]);
  };

  const getMessagesSinceLastAgentChange = ({ history, message }) => {
    // Clone history if not provided
    if (!history) {
      history = JSON.parse(JSON.stringify(chatHistory));
    }

    // Start search from the selected message if provided
    let messageIndex = history.length - 1;
    if (message) {
      for (let i = history.length - 1; i >= 0; i--) {
        if (history[i].id === message.id) {
          messageIndex = i;
          break;
        }
      }
    }

    // Iterate backwards through the history until we find an agent changed message
    for (let i = messageIndex; i >= 0; i--) {
      if (history[i].type === 'error' || history[i].type === 'agentchanged') {
        // Return the history between the agent changed message and the message index
        return history.slice(i + 1, messageIndex + 1);
      }
    }

    return history.slice(0, messageIndex + 1);;
  };

  const socketRef = useRef(null);

  const stopChat = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  };

  const postChat = async (incomingPrompt, images=[]) => {
    setLoading(true);
    setIncomingRequest(true);
    setLoadingComment();

    let streamResponses = getFirstDefinedValue(selectedAgentRevision?.streamResponses, defaultAgentRevision.streamResponses);

    try {
      const chatHistoryClone = JSON.parse(JSON.stringify(chatHistory));

      const messagesSinceLastAgentChange = getMessagesSinceLastAgentChange({ history: chatHistoryClone });

      let fullChatHistory = [
        ...messagesSinceLastAgentChange.filter(
          chatItem => (
            (chatItem.role === 'system' || chatItem.role === 'user' || chatItem.role === 'assistant')
          )
        ),
      ];

      // Convert any datatable messages from JSON to text
      fullChatHistory = fullChatHistory.map(chatItem => {
        if (chatItem.type === 'datatable') {
          let limitedArray = chatItem.content.slice(0, 1000);
          // If the item is NOT an array, convert it to an array with one item
          if (!Array.isArray(limitedArray)) {
            limitedArray = [limitedArray];
          }
          // Convert the JSON object to CSV, limiting to 1000 records
          return {
            ...chatItem,
            content: jsonToCSV(limitedArray)
          };
        } else {
          // If it's not an object, return the item unchanged
          return chatItem;
        }
      });

      // Check if incomingPrompt contains an agent700Command
      const isCommand = incomingPrompt.hasOwnProperty('agent700Command');

      // If it's a command, we don't add the message to the chat history as a regular message
      if (!isCommand) {
        let newChatItem = {
          id: uuid(),
          role: 'user',
          content: incomingPrompt
        };
        if (images.length > 0) {
          newChatItem.content = [];
          newChatItem.content.push({type: 'text', text: incomingPrompt});
          images.forEach(i => newChatItem.content.push({type: 'image_url', image_url: {url: i.url}}));
        }
        fullChatHistory.push(newChatItem);
      } else {
        fullChatHistory.push({
          id: uuid(),
          role: 'user',
          content: '...Executing command...'
        });
      }

      // Also filter out if selectedAgentRevision?.model === 'claude-2.0' && chatItem.type === 'introductoryText'
      if (selectedAgentRevision?.model === 'claude-2.0') {
        fullChatHistory = fullChatHistory.filter(chatItem => chatItem.type !== 'introductorytext');
      }

      // Limit chat history to last 300000 characters.
      let limitedChatHistory = [];
      // If full document analysis is enabled, don't limit the chat history
      if (!selectedAgentRevision.fullDocAnalysis) {
        let cumulativeQuestionsLength = 0;
        for (let i = fullChatHistory.length - 1; i >= 0; i--) {
          const currentQuestion = fullChatHistory[i];
          const currentQuestionLength = currentQuestion.content.length;
          if (cumulativeQuestionsLength + currentQuestionLength < 300000) {
            limitedChatHistory.unshift(currentQuestion); // Use unshift instead of push
            cumulativeQuestionsLength += currentQuestionLength;
          } else {
            break;
          }
        }
      } else {
        limitedChatHistory = fullChatHistory;
      }

      // Master prompt takes priority over that
      if (selectedAgentRevision?.masterPrompt?.trim().length > 0) {
        limitedChatHistory.unshift(
          {
            id: uuid(),
            role: 'system',
            content: parameterizeDataContextString(
              selectedAgentRevision?.masterPrompt?.trim().length > 0 ? selectedAgentRevision.masterPrompt : '',
              { ...selectedWorkflowRevision?.dataContext }
            ),
          }
        )
      }

      // If the first prompt isn't a system prompt because maybe the prompt chain and master prompt was empty, insert a blank system prompt
      if (limitedChatHistory.length > 0 && limitedChatHistory[0].role !== 'system') {
        limitedChatHistory.unshift(
          {
            id: uuid(),
            role: 'system',
            content: '',
          }
        )
      }


      let requestData = {
        agentId: selectedAgent?.id,
        messages: limitedChatHistory
      }

      // If the agent is shared AND there is a revision ID in the URL, use that revision ID
      // otherwise the default behavior is to just always use the latest or published revision ID
      if (isSharedAgent) {
        if (urlProvidedRevisionId) {
          requestData.agentRevisionId = urlProvidedRevisionId;
        }

        // If the agent is not shared, use the UI-selected agent revision ID
      } else {
        requestData.agentRevisionId = selectedAgentRevision?.id;
      }

      // Other parameters only if user is logged in
      if (isLoggedIn) {
        requestData = Object.assign(requestData, {
          masterPrompt: selectedAgentRevision?.masterPrompt || '',
          imageDimensions: selectedAgentRevision?.imageDimensions || '1024x1024',
          model: getFirstDefinedValue(selectedAgentRevision?.model, defaultAgentRevision.model),
          temperature: getFirstDefinedValue(selectedAgentRevision?.temperature, defaultAgentRevision.temperature),
          topP: getFirstDefinedValue(selectedAgentRevision?.topP, defaultAgentRevision.topP),
          maxTokens: getFirstDefinedValue(selectedAgentRevision?.maxTokens, defaultAgentRevision.maxTokens),
          scrubPii: getFirstDefinedValue(selectedAgentRevision?.scrubPii, defaultAgentRevision.scrubPii),
          piiThreshold: getFirstDefinedValue(selectedAgentRevision?.piiThreshold, defaultAgentRevision.piiThreshold),
          smartDocEvaluation: getFirstDefinedValue(selectedAgentRevision?.smartDocEvaluation, defaultAgentRevision.smartDocEvaluation),
          fullDocAnalysis: getFirstDefinedValue(selectedAgentRevision?.fullDocAnalysis, defaultAgentRevision.fullDocAnalysis),
          fullDocChunkSize: getFirstDefinedValue(selectedAgentRevision?.fullDocChunkSize, defaultAgentRevision.fullDocChunkSize),
          fullDocChunkOverlap: getFirstDefinedValue(selectedAgentRevision?.fullDocChunkOverlap, defaultAgentRevision.fullDocChunkOverlap),
          fullDocMaxLength: getFirstDefinedValue(selectedAgentRevision?.fullDocMaxLength, defaultAgentRevision.fullDocMaxLength),
        });
      }

      if (streamResponses) {

        requestData.Authorization = `Bearer ${user?.accessToken}`;

        const connectSocket = () => {
          socketRef.current = io(routes.streamChat, {
            extraHeaders: {
              Authorization: `Bearer ${user?.accessToken}`
            },
            transports: ['websocket', 'polling'],
            reconnectionAttempts: maxReconnectAttempts,
            reconnectionDelay: 1000, // Delay between reconnection attempts
          });

          socketRef.current.on('connect', () => {
            setIsConnected(true);
            setReconnectAttempts(0);
            removeLoadingComments();
          });

          socketRef.current.on('disconnect', () => {
            setIsConnected(false);
            setLoading(false);
          });

          socketRef.current.on('reconnect_attempt', () => {
            openSnackbar('Attempting to reconnect...', 'info')
            setReconnectAttempts(prev => prev + 1);
          });

          socketRef.current.on('reconnect_failed', () => {
            openSnackbar('Failed to reconnect to the server. Please refresh the page.', 'error');
          });

          socketRef.current.on('chat_message_response', (data) => {
            if (!data.finish_reason || (data.finish_reason && data.finish_reason.toLowerCase() !== 'stop')) {
              // Accumulate the response
              bufferedResponseRef.current += data.content;
              addAIResponseToChatHistory(data); // Continue to display streaming response
            } else {
              // Final chunk received
              bufferedResponseRef.current += data.content;
              addAIResponseToChatHistory(data); // Add the final chunk

              // Now process the full response for SQL
              processFullResponseForSQL(bufferedResponseRef.current);

              // Reset the buffer
              bufferedResponseRef.current = '';
            }
          });

          socketRef.current.on('error', (data) => {
            console.log(`Error: ${data.error}, Code: ${data.code}`);
            if (data.code === 401) {
              // Handle unauthorized error
            }
            if (data.code === 402) {
              // Handle payment error
            }
            logout();
          });

          socketRef.current.on('data_table', (dataTableResponse) => {
            console.log('Received data from server in chat namespace:', dataTableResponse);
            addDataTableToChatHistory(dataTableResponse.content, 'user');
            setTimeout(() => {
              addAIResponseToChatHistory({ content: 'Data successfully parsed. Feel free to explore or ask questions.' });
            }, 0);
          });

          socketRef.current.on('scrubbed_message', (data) => {
            insertScrubbedMessage(data.content);
          });

          socketRef.current.emit('send_chat_message', requestData);
        };

        connectSocket();
        return;
      }

      const requestConfiguration = {
        method: 'post',
        url: routes.chat,
        headers: {
          'Authorization': `Bearer ${user?.accessToken}`,
          'Content-Type': 'application/json'
        },
        data: requestData
      };

      let response = await axios(requestConfiguration);

      if (requestData.scrubPii) {
        insertScrubbedMessage(response.data.scrubbed_message);
      }

      addAIResponseToChatHistory({ content: response.data.response });

      // Process the full response for SQL
      await processFullResponseForSQL(response.data.response);

    } catch (error) {
      console.error(error);
      addErrorResponseToChatHistory();
      setLoading(false);
    } finally {
      if (!streamResponses) {
        setLoading(false);
      }
      removeLoadingComments();
      setIncomingRequest(false);
    }
  };

  const submitUserPromptToServer = (prompt, images=[]) => {
    if (localAnalysisEnabled[selectedAgent.id]) {
      removePreviousDataTables();
    }
    setPrompt(prompt);
    addUserPromptToChatHistory(prompt, images);
    setIncomingRequest(true);
    postChat(prompt, images);
  };

  const stopServerResponse = () => {
    setIncomingRequest(false);
    stopChat();
  };

  const copyToClipboard = async (text, message = 'Copied to clipboard') => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar(message, 'success');
    } catch (error) {
      openSnackbar('Failed to copy to clipboard', 'error');
    }
  };

  const copyStyledContentToClipboard = async (elementId, successMessage = 'Copied to clipboard', errorMessage = 'Failed to copy to clipboard') => {
    try {
      // Clone the target element
      const originalElement = document.getElementById(elementId);
      const clonedElement = originalElement.cloneNode(true);

      // Override styles you don't want to copy
      clonedElement.style.fontFamily = ''; // Resets font family
      clonedElement.style.backgroundColor = 'transparent'; // Resets background color
      // Add more style resets as needed

      // Serialize the cloned element's HTML content for the HTML clipboard
      const clonedElementHtml = clonedElement.outerHTML;
      const htmlBlob = new Blob([clonedElementHtml], { type: 'text/html' });

      // Create a plain text representation for the plain text clipboard
      const plainText = clonedElement.innerText;
      const textBlob = new Blob([plainText], { type: 'text/plain' });

      // Use Clipboard API with ClipboardItem containing both HTML and plain text
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': htmlBlob,
          'text/plain': textBlob,
        })
      ]);

      // Show success message
      openSnackbar(successMessage, 'success');
    } catch (error) {
      // Show error message
      openSnackbar(errorMessage, 'error');
    }
  };

  const copyConversationToClipboard = async (startMessage) => {
    try {
      // Assuming getMessagesSinceLastAgentChange returns an array of messages up to the selected message
      const conversationMessages = getMessagesSinceLastAgentChange({ message: startMessage });

      // Start with the heading
      let conversationText = '';

      // Loop through the messages to format and add them to the conversation text
      for (const message of conversationMessages) {
        // Append the role of message (AI/User) as a prefix. Adjust these types based on your actual data.
        if (message.role === 'assistant') {
          conversationText += "https://www.agent700.ai/:\n-----------------------------------------------\n\n";
        } else if (message.role === 'user') {
          conversationText += "User:\n-----------------------------------------------\n\n";
        }

        // Check if the message content is an object (which includes arrays)
        if (typeof message.content === 'object') {
          // Directly stringify the object with indentation for readability
          const formattedJson = JSON.stringify(message.content);
          conversationText += formattedJson + "\n\n";
        } else {
          // For non-object types, append directly (handles strings and other types gracefully)
          conversationText += message.content + "\n\n";
        }
      }

      // Copy the formatted conversation text to the clipboard
      await navigator.clipboard.writeText(conversationText.trim());
      openSnackbar('Conversation copied to clipboard', 'success');
    } catch (error) {
      openSnackbar('Failed to copy conversation to clipboard', 'error');
    }
  };

  return (
    <ChatContext.Provider
      value={{
        userPrompt: prompt,
        chatHistory,
        incomingRequest,
        loading,
        clearChatHistory,
        submitUserPromptToServer,
        stopServerResponse: stopServerResponse,
        getMessagesSinceLastAgentChange,
        setChatHistory,
        copyToClipboard,
        copyStyledContentToClipboard,
        copyConversationToClipboard,
        addDataTableToChatHistory,
        addAIResponseToChatHistory,
        stopChat,
        isConnected, // Add this to the context value
        reconnectAttempts, // Add this to the context value
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// ----------------------------------------------------------------------

export { ChatContextProvider, ChatContext };