// AgentContext.js
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
//import { useRemoteStorage } from 'src/contexts/RemoteStorageContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import axios from 'axios';
import routes from 'src/config/routes';
import { useAuth } from 'src/contexts/AuthContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { useSqlContext } from 'src/contexts/SqlContext';
import useLocalStorage from './../hooks/useLocalStorage';

const AgentContext = createContext();

const defaultPrompt = {
  id: uuid(),
  systemPromptText: ""
};

const defaultAgentRevision = {
  id: 0,
  name: "New Agent",
  temperature: .7,
  topP: 1,
  maxTokens: 2048,
  scrubPii: false,
  piiThreshold: 0.32,
  masterPrompt: "",
  introductoryText: '',
  streamResponses: true,
  model: "gpt-4o",
  imageDimensions: '1024x1024',
  allowPublicSharing: false,
  customShareUrl: '',
  smartDocEvaluation: false,
  smartDocChunkSize: 4000,
  smartDocChunkOverlap: 400,
  smartDocEmbeddingModel: 'all-MiniLM-L6-v2',
  smartDocTopK: 5,
  fullDocAnalysis: false,
  fullDocChunkSize: 1500,
  fullDocChunkOverlap: 200,
  fullDocMaxLength: 5000000,
  hasPhoneNumber: false,
  phoneNumber: null
}

const defaultAgent = {
  id: uuid(),
  currentRevision: 0,
  revisions: [
    defaultAgentRevision
  ]
}

const defaultAgents = [];

function AgentProvider({ children }) {

  const authContext = useAuth();
  const { fetchOrganizationMembers, selectedOrganization } = useOrganizationContext();
  const { dbSql, getSqlTableDetails } = useSqlContext();
  const [selectedAgent, setSelectedAgent] = useState(null);

  const { openSnackbar } = useContext(SnackbarContext);
  //const { localDataStore, setDataAndSync, syncStatus } = useRemoteStorage('agents', defaultAgents);
  const [agents, setAgents] = useState(defaultAgents);
  const [selectedAgentRevisionIndex, setSelectedAgentRevisionIndex] = useState(0);
  const [selectedAgentRevision, setSelectedAgentRevision] = useState(null);
  const [lastSavedAgentRevisions, setLastSavedAgentRevisions] = useState({});

  const [localAnalysisEnabled, setLocalAnalysisEnabled] = useLocalStorage('localAnalysisEnabled', {});
  const [selectedLocalFilesForAnalysis, setSelectedLocalFilesForAnalysis] = useLocalStorage('localFiles', {}); // { agentId: { fileName: boolean } }

  //console.log('Current selected agent: ', selectedAgent);
  //console.log('Current selected revision: ', selectedAgentRevision);

  const currentPath = window.location.pathname;
  const isSharedAgent = currentPath.startsWith('/share/agent/')
  const pathSegments = currentPath.split('/')
  const urlProvidedAgentId = pathSegments[3]
  const revisionGuidIndex = pathSegments.indexOf('revision')
  const urlProvidedRevisionId = revisionGuidIndex > 0 ? pathSegments[revisionGuidIndex+1] : null

  const isRefreshingRef = useRef(false);

  useEffect(() => {
    if (authContext?.isLoggedIn) {
      fetchOrganizationMembers();
    }

    if (!authContext?.isLoggedIn) {
      setAgents([]);
    }

    if (!!urlProvidedAgentId) {
      refreshAgentsFromServer();
    }
  }, [urlProvidedAgentId, authContext?.isLoggedIn]);

  const refreshAgentsFromServer = async () => {
    if (isRefreshingRef.current) {
      return;
    }
    isRefreshingRef.current = true;

    let agentsFromServer = [];
    // Share route
    if (urlProvidedAgentId) {
      const agentAndRevisionQueryPath = urlProvidedRevisionId ? `${urlProvidedAgentId}/revision/${urlProvidedRevisionId}` : urlProvidedAgentId;
      await axios.get(`${routes.agents}/${agentAndRevisionQueryPath}`)
        .then(response => {
          //console.log('Shared agent: ', response.data)
          setAgents([response.data.sort]);
          setSelectedAgent(response.data)
          setSelectedAgentRevision(response.data.revisions[0]);
        })
        .catch(error => {
          openSnackbar("Error fetching agent.", "error");
        }).finally(() => {
          isRefreshingRef.current = false;
        });;

      // Normal load agents route
    } else {
      await axios.get(`${routes.agents}?organization_id=${selectedOrganization}`)
        .then(response => {
          agentsFromServer = [...response.data, ...defaultAgents];
          setAgents(agentsFromServer);
        }).catch(error => {
          openSnackbar("Error fetching agents.", "error");
        }).finally(() => {
          isRefreshingRef.current = false;
        });
    }
    return agentsFromServer;
  }

  const addAgent = (agent) => {
    setAgents(prevAgents => [agent, ...prevAgents]);
  };

  const saveAgentRevision = async (agent, updatedAgentRevision) => {
    try {
      const response = await axios.put(`${routes.agents}/${agent.id}`, updatedAgentRevision);

      if (response.status === 200 && response.data && response.data.agent) {
        setAgents(prevAgents => {
          const otherAgents = prevAgents.filter(a => a.id !== agent.id);

          // If the agent is new, add the revisions from the response
          const isNewAgent = !agent.revisions || agent.revisions.length === 0;

          // Otherwise, add the revisions from the response to the existing revisions
          const updatedRevisions = isNewAgent ?
            [...response.data.agent.revisions] :
            [...agent.revisions, ...response.data.agent.revisions];

          // Update the agent with the new revisions
          const updatedAgent = {
            ...updatedAgentRevision,
            ...response.data.agent,
            revisions: updatedRevisions
          };

          setSelectedAgentRevision(updatedRevisions[updatedRevisions.length - 1]);
          setSelectedAgentRevisionIndex(updatedRevisions.length - 1);
          setSelectedAgent(updatedAgent);
          setLastSavedAgentRevisions({ ...lastSavedAgentRevisions, [agent.id]: updatedRevisions[updatedRevisions.length - 1] });

          openSnackbar('Agent saved successfully!', 'success');
          return [updatedAgent, ...otherAgents];
        });
      } else {
        openSnackbar('Failed to save agent. Unexpected response.', 'error');
      }
    } catch (error) {
      console.error("Error saving agent:", error);
      openSnackbar('Failed to save agent. Please try again.', 'error');
    }
  };



  const handleSelectPreviousRevision = () => {
    if (selectedAgentRevisionIndex > 0) {
      const newIndex = selectedAgentRevisionIndex - 1;
      setSelectedAgentRevisionIndex(newIndex);
      setSelectedAgentRevision(selectedAgent.revisions[newIndex]);
    }
  };

  const handleSelectNextRevision = () => {
    if (selectedAgentRevisionIndex < selectedAgent.revisions.length - 1) {
      const newIndex = selectedAgentRevisionIndex + 1;
      setSelectedAgentRevisionIndex(newIndex);
      setSelectedAgentRevision(selectedAgent.revisions[newIndex]);
    }
  };

  const deleteAgent = async (agentId) => {
    try {
      const response = await axios.delete(`${routes.agents}/${agentId}`);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        openSnackbar('Agent not found on server. Deleting local copy.', 'warning');
      } else {
        console.error("Error deleting agent:", error);
        openSnackbar('Failed to delete agent. Please try again.', 'error');
      }
    }
    setAgents(prevAgents =>
      prevAgents.filter(agent => agent.id !== agentId)
    );
  };

  const getLocalFileNamesForAgent = () => {
    try {
        const tableDetails = getSqlTableDetails(dbSql);
        // Get table names from the keys of tableDetails and filter them
        return Object.keys(tableDetails)
            .filter(tableName => tableName.startsWith(selectedAgent.id));
    } catch (e) {
        console.log(`Error reading file names: ${e}`);
        return [];
    }
  };


  const updateLocalAnalysisEnabled = (value) => {
    setLocalAnalysisEnabled({
      ...localAnalysisEnabled,
      [selectedAgent.id]: value
    });
  };

  const updateSelectedLocalFilesForAnalysis = (files) => {
    console.log({files})
    setSelectedLocalFilesForAnalysis(prev => ({
      ...prev,
      [selectedAgent.id]: files
    }));
  };

  return (
    <AgentContext.Provider value={{
      agents,
      addAgent,
      setSelectedAgentRevision,
      deleteAgent,
      selectedAgent,
      setSelectedAgent,
      defaultPrompt,
      defaultAgent,
      defaultAgentRevision,
      handleSelectNextRevision,
      handleSelectPreviousRevision,
      selectedAgentRevision,
      selectedAgentRevisionIndex,
      setSelectedAgentRevisionIndex,
      saveAgentRevision,
      setAgents,
      refreshAgentsFromServer,
      urlProvidedRevisionId,
      lastSavedAgentRevisions,
      setLastSavedAgentRevisions,
      getLocalFileNamesForAgent,
      localAnalysisEnabled,
      selectedLocalFilesForAnalysis,
      updateLocalAnalysisEnabled,
      updateSelectedLocalFilesForAnalysis,
    }}>
      {children}
    </AgentContext.Provider>
  );
}

function useAgentContext() {
  const context = useContext(AgentContext);
  if (!context) {
    throw new Error("useAgentContext must be used within an AgentProvider");
  }
  return context;
}

export { AgentProvider, useAgentContext };
