// src/contexts/SqlContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeSqlJs, executeSql } from 'src/sqlDb';

const SqlContext = createContext(null);

export const SqlContextProvider = ({ children }) => {
  const [dbSql, setDbSql] = useState(null);

  useEffect(() => {
    const init = async () => {
      const initializedDb = await initializeSqlJs();
      setDbSql(initializedDb);
    };
    init();
  }, []);

  const getSqlTableDetails = () => {
    try {
      const tables = {};
      
      // Get all table names first
      const tableQuery = "SELECT name FROM sqlite_master WHERE type='table';";
      const tableResult = dbSql.exec(tableQuery);
      
      if (tableResult.length > 0) {
        for (const [tableName] of tableResult[0].values) {
          // For each table, get column information
          const columnQuery = `PRAGMA table_info("${tableName}");`;
          const columnResult = dbSql.exec(columnQuery);
          
          if (columnResult.length > 0) {
            const columns = columnResult[0].values.map(col => ({
              name: col[1],       // column name
              // type: col[2],       // data type
              // notNull: col[3],    // not null constraint
              // defaultValue: col[4],// default value
              // primaryKey: col[5]  // primary key
            }));
            
            tables[tableName] = columns;
          }
        }
      }
      
      return tables;
    } catch (error) {
      console.error('Error getting table details:', error);
      return {};
    }
  };

  const runQuery = async (query) => {
    if (!dbSql) {
      throw new Error('Database not initialized');
    }
    try {
      const result = await dbSql.exec(query);
      return result;
    } catch (error) {
      throw new Error('SQL Execution Failed: ' + error.message);
    }
  };

  return (
    <SqlContext.Provider value={{ runQuery, getSqlTableDetails, dbSql }}>
      {children}
    </SqlContext.Provider>
  );
};

export const useSqlContext = () => {
  const context = useContext(SqlContext);
  if (!context) {
    throw new Error("useSqlContext must be used within a SqlContextProvider");
  }
  return context;
};