// AgentDetails.js
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import {
  Box, TextField, IconButton, Button, List, ListItem,
  ListItemText, ListItemIcon, Typography, Select, InputLabel, MenuItem, Checkbox, FormControl,
  FormControlLabel, Collapse, useMediaQuery, ListSubheader,
  Stack,
  InputAdornment,
  Chip, Tooltip,
  Switch
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InfoIcon from '@mui/icons-material/Info';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

import GroupIcon from '@mui/icons-material/Group';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
//import AgentPromptChain from './AgentPromptChain';
import { TextFieldStyled, DrawerPaperStyled, TitleStyled, ListItemButtonStyled, FormStyled, SubmitButtonStyled, ListItemStyled, AddAgentButtonStyled, GrayBackgroundStyled, SaveButtonContainerStyled, SaveButtonStyled, RevisionNavigationStyled } from './AgentDetailsStyles';
import { useAgentContext } from 'src/contexts/AgentContext';
import { useNavigationContext } from 'src/contexts/NavigationContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { useAuth } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import AlignmentDataAutocompleteEditor from './AlignmentDataAutocompleteEditor/AlignmentDataAutocompleteEditor'

import { useNavigate } from 'react-router-dom';
import { useQASheetContext } from 'src/contexts/QASheetContext';
import routes from 'src/config/routes';
import axios from 'axios';
import FileSaver from 'file-saver';
import { debounce, set, isNaN } from 'lodash';

import { v4 as uuid } from 'uuid';

import { hasAgentRevisionChanged } from 'src/components/utils';
import AgentToolItem from './AgentToolItem';

function AgentDetails() {
  const {
    selectedAgent,
    setSelectedAgentRevision,
    selectedAgentRevision,
    saveAgentRevision,
    handleSelectPreviousRevision,
    handleSelectNextRevision,
    selectedAgentRevisionIndex,
    lastSavedAgentRevisions,
    localAnalysisEnabled,
    updateLocalAnalysisEnabled,
    selectedLocalFilesForAnalysis,
    updateSelectedLocalFilesForAnalysis,
    getLocalFileNamesForAgent
  } = useAgentContext();
  const { openNavigationPane } = useNavigationContext();
  const { user } = useAuth();
  //console.log('user', user);

  const [isPromptChainExpanded, setIsPromptChainExpanded] = useState(false);
  const [tempAgentRevision, setTempAgentRevision] = useState({ ...selectedAgentRevision });

  const [isPublishExpanded, setPublishExpanded] = useState(false);
  const [isAdvancedSettingsExpanded, setAdvancedSettingsExpanded] = useState(false);
  const [isToolsExpanded, setToolsExpanded] = useState(false);
  const [isTestSuitesExpanded, setTestSuitesExpanded] = useState(false);

  const [isInternalSharingExpanded, setIsInternalSharingExpanded] = useState(false);
  const [internalShareEmails, setInternalShareEmails] = useState([]);
  const [sharedOrganizationIds, setSharedOrganizationIds] = useState([]);

  const [isInteractionDesignExpanted, setIsInteractionDesignExpanted] = useState(true);

  const [sharedUserEmails, setSharedUserEmails] = useState([]);

  const { openSnackbar } = useContext(SnackbarContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const { fetchQASheetForAgentCallback } = useQASheetContext();
  const { members } = useOrganizationContext();

  const [autocompleteComponentKey, setAutocompleteComponentKey] = useState(0);

  const fileInputRef = useRef(null);
  const [useOCR, setUseOCR] = useState(false);
  const [isAppendFileDisabled, setIsAppendFileDisabled] = useState(false);

  const togglePromptChain = () => {
    setIsPromptChainExpanded(prevState => !prevState);
  };

  const agentSupportsReasoningEffort = (tempAgentRevision.model === 'o1-preview' || tempAgentRevision.model === 'o1-mini' || tempAgentRevision.model === 'o1' || tempAgentRevision.model === 'o3-mini');
  const sonarAgent = tempAgentRevision?.model?.includes('sonar');
  const [filesToLoad, setFilesToLoad] = useState([]);

  const toggleLocalDataAnalysis = () => {
    updateLocalAnalysisEnabled(!localAnalysisEnabled[selectedAgent.id]);
  };

  const handleFileSelection = (fileName) => {
    // Get current files for this agent or initialize empty array
    const currentFiles = selectedLocalFilesForAnalysis[selectedAgent.id] || [];

    // Toggle file selection
    const newSelectedFiles = currentFiles.includes(fileName)
      ? currentFiles.filter(file => file !== fileName)
      : [...currentFiles, fileName];

    // Update the files for this agent
    updateSelectedLocalFilesForAnalysis(newSelectedFiles);
  };

  // Fetch file names when agent changes or when a new file is uploaded
  const refreshFileNames = async () => {
    try {
      const names = await getLocalFileNamesForAgent();
      setFilesToLoad(names);

      // // Find any new files that aren't in selectedFiles and add them
      // const currentFiles = selectedLocalFilesForAnalysis[selectedAgent.id] || [];
      // const newFiles = names.filter(name => !currentFiles.includes(name));
      // if (newFiles.length > 0) {
      //   updateSelectedLocalFilesForAnalysis([...currentFiles, ...newFiles]);
      // }
    } catch (error) {
      console.error('Error getting file names:', error);
    }
  };

  // Remove the useStyles and replace class-based elements with styled components or sx props

  const maxOutputTokensPerModel = {
    "gpt-4o": 16384,
    "gpt-4o-mini": 16384,
    "gpt-4.5-preview": 16384,
    "gpt-4-turbo-preview": 4096,
    "gemini-1.5-pro-latest": 8192,
    "gemini-2.5-pro-exp-03-25": 65536,
    "gemini-2.0-flash": 8192,
    "gemini-2.0-flash-lite": 8192,
    "gemini-1.5-flash": 8192,
    "gemini-1.5-flash-8b": 8192,
    "claude-3-7-sonnet-latest": 8192,
    "claude-3-5-sonnet-latest": 8192,
    "claude-3-5-haiku-latest": 8192,
    "claude-instant-1.2": 4096,
    "gpt-3.5-turbo": 4096,
    "gpt-4": 8192,
    "open-mistral-7b": 2048,
    "open-mixtral-8x7b": 2048,
    "open-mistral-nemo": 2048,
    "mistral-small-latest": 1024,
    "mistral-medium-latest": 2048,
    "mistral-large-latest": 4096,
    "codestral-latest": 4096,
    "o1-preview": 32768,
    "o1-mini": 65536,
    "o3-mini": 100000,
    "o1": 100000,
    "grok-2-latest": 4096,
    "sonar": 8000,
    "sonar-pro": 8000,
    "sonar-reasoning": 8000,
    "sonar-reasoning-pro": 8000,
  }

  const handleSaveAgent = () => {
    if (!selectedAgent.sharedWithUser) {
      saveAgentRevision(selectedAgent, tempAgentRevision);
    }
  };

  const handleNavigateToQASheet = () => {
    fetchQASheetForAgentCallback(selectedAgent.id);
    navigate(`/agent/${selectedAgent.id}/qasheet`);
  };

  const handleOrganizationShareChange = (event, newValue) => {
    setSharedOrganizationIds(newValue.map(option => option.id));
  };

  const handleInternalShare = async () => {
    try {
      const response = await axios.post(`${routes.agents}/${selectedAgent.id}/share`, {
        sharedUserEmails: internalShareEmails,  // Send array of emails
        sharedOrganizationIds: sharedOrganizationIds,  // Send array of organization IDs
      });
      if (response.data.status === 'success') {
        openSnackbar('Agent sharing updated successfully', 'success');
      }
    } catch (error) {
      console.error('Error updating agent sharing:', error);
      openSnackbar('Failed to update agent sharing. Please try again.', 'error');
    }
  };

  const handleRatingsExportClick = () => {
    axios.get(routes.ratingsExport,
      { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data],
          { type: 'text/csv' });
        FileSaver.saveAs(blob, 'ratings.csv');
      });
  };

  const handleUpdateAgentRevisionProperty = (event, property, allowBlank = true) => {
    let value = event.target.value;
    const min = 0;
    const floatProperties = ['temperature', 'topP', 'piiThreshold'];
    const booleanProperties = ['scrubPii', 'streamResponses', 'allowPublicSharing', 'smartDocEvaluation', 'fullDocEvaluation', 'hasPhoneNumber', 'thinkingEnabled'];

    // If value < min or value > max, set value to closest limit
    const limitValue = (value, min, max) => {
      let parsedValue = value;
      if (!parsedValue && parsedValue !== 0) {
        if (allowBlank) {
          return '';
        } else {
          parsedValue = 0;
        }
      }
      return Math.max(min, Math.min(max, parsedValue));
    };

    if (floatProperties.includes(property)) {
      value = parseFloat(value);
      const max = property === 'temperature' ? 2 : 1;
      value = limitValue(value, min, max);
    }

    if (property === 'maxTokens') {
      value = parseInt(value, 10);
      const max = maxOutputTokensPerModel[tempAgentRevision.model];
      value = limitValue(value, min, max);
    }

    if (booleanProperties.includes(property)) {
      value = event.target.checked;
    }

    if (property === 'customShareUrl') {
      value = value.replace(/[^a-zA-Z0-9-]/g, '-');
    }

    if (property === 'streamResponses') {
      value = !value;
    }

    updateAgentRevisionProperty(property, value);
  };

  const handleAddDomain = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      const domain = event.target.value.trim();
      
      // Regular expression to validate domain
      // Allows optional leading '-'
      // Allows optional 'www.'
      // Requires domain name and TLD
      // Does not allow protocols or paths
      const domainRegex = /^-?(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,}$/;
  
      if (domainRegex.test(domain)) {
        setTempAgentRevision(prevState => ({
          ...prevState,
          searchDomainFilter: [...prevState.searchDomainFilter, domain],
        }));
        event.target.value = ''; // Clear the input field
      } else {
        // Show error message to user
        openSnackbar('Please enter a valid domain (e.g., www.google.com or -www.google.com)', 'error');
      }
    }
  };

  const handleDeleteDomain = (domainToDelete) => {
    setTempAgentRevision(prevState => ({
      ...prevState,
      searchDomainFilter: prevState.searchDomainFilter.filter(domain => domain !== domainToDelete),
    }));
  };

  const updateAgentRevisionProperty = (property, value) => {
    setTempAgentRevision(prevState => ({
      ...prevState,
      [property]: value,
    }));
    debouncedUpdateSelectedAgentProperty(property, value);
  }

  const handleSelectIntelligentEngineProperty = (mode) => {
    const update = {
      smartDocEvaluation:  mode === 'smart',
      fullDocAnalysis:  mode === 'full'
    }
    setTempAgentRevision(prevState => ({
      ...prevState,
      ...update,
    }));
    setSelectedAgentRevision((prevState) => {
      return { ...tempAgentRevision, ...update }
    });
  }

  const getUniqueOrganizations = () => {
    const organizations = [];
    const organizationMap = new Map();

    if (!members) {
      return []
    }

    for (const member of members) {
      if (!organizationMap.has(member.organizationId)) {
        organizationMap.set(member.organizationId, true); // set any value to Map
        organizations.push({
          id: member.organizationId,
          name: member.organization,
        });
      }
    }

    return organizations;
  };

  const handleSelectAllMembers = (organization) => {
    const organizationId = members.find(member => member.organization === organization)?.organizationId;
    if (!organizationId) return;

    setInternalShareEmails((prevEmails) => {
      const organizationEmails = members
        .filter(member => member.organization === organization)
        .map(member => member.email);
      const allSelected = organizationEmails.every(email => prevEmails.includes(email));
      if (allSelected) {
        // Deselect all members of this organization
        return prevEmails.filter(email => !organizationEmails.includes(email));
      } else {
        // Select all members of this organization
        return [...new Set([...prevEmails, ...organizationEmails])];
      }
    });
  };

  const debouncedUpdateSelectedAgentProperty = useCallback(debounce((property, value) => {
    setSelectedAgentRevision((prevState) => {
      return { ...tempAgentRevision, [property]: value }
    });
  }, 500), [tempAgentRevision.id, selectedAgentRevision]);

  // New agent selected or persisted?
  useEffect(() => {
    setTempAgentRevision((prevState) => {
      return (tempAgentRevision.agentId === selectedAgentRevision.agentId && tempAgentRevision.id === selectedAgentRevision.id) ? tempAgentRevision : { ...selectedAgentRevision };
    });
  }, [selectedAgentRevision]);

  useEffect(() => {
    if (selectedAgent) {
      refreshFileNames();
    }
  }, [selectedAgent, selectedLocalFilesForAnalysis]);

  // For when revision # changes
  useEffect(() => {
    // Set autocomplete key to a random vaalue to force a re-render of the component
    setAutocompleteComponentKey(uuid());
  }, [selectedAgentRevision.id]);

  useEffect(() => {
    const fetchSharingInfo = async () => {
      try {
        const response = await axios.get(`${routes.agents}/${selectedAgent.id}/sharing-info`);
        setSharedUserEmails(response.data.emails);
        setInternalShareEmails(response.data.emails);  // Initialize with shared users
        setSharedOrganizationIds(response.data.organizationIds);
      } catch (error) {
        console.error('Error fetching sharing info:', error);
        // openSnackbar('Failed to fetch sharing info. Please try again.', 'error');
      }
    };

    if (selectedAgent.id) {
      fetchSharingInfo();
    }
  }, [selectedAgent.id, openSnackbar]);

  const shareUrl = !!tempAgentRevision.customShareUrl
    ? `${process.env.REACT_APP_HOST}/share/agent/${selectedAgent.id}/${tempAgentRevision.customShareUrl}`
    : `${process.env.REACT_APP_HOST}/share/agent/${selectedAgent.id}`;

  const lastSavedRevision = lastSavedAgentRevisions[tempAgentRevision.agentId] || {};

  let alignmentInstructionsPlaceholder = 'ex: Act as a Project Manager with expertise in Jira, Scrum, and Agile Methodologies.';
  alignmentInstructionsPlaceholder += !isMobile ? ' Examine provided user stories and provide a description, test criteria, sub-tasks and estimates in story points.' : '';

  const organizationOptions = getUniqueOrganizations();

  let documentAnalysisMode = 'none';
  if (selectedAgentRevision.smartDocEvaluation) {
    documentAnalysisMode = 'smart';
  } else if (selectedAgentRevision.fullDocAnalysis) {
    documentAnalysisMode = 'full'
  }

  let maxResponseTokens = maxOutputTokensPerModel[tempAgentRevision.model];
  const tools = [
    {
      title: 'Intelligent Retrieval Augmented Generation',
      value: 'smart',
      checked: documentAnalysisMode === 'smart',
      visible: !tempAgentRevision?.model.startsWith('img'),
      onChange:(event) => {
        const mode = event.target.checked ? event.target.value : 'none';
        handleSelectIntelligentEngineProperty(mode);
      },
      description: 'Better, faster results for large document or Context Library queries, up to 1/100th the cost. Documents uploaded or used in the System Message will be searched for relevancy using Agent700’s Intelligent RAG Solution.',
    },
    {
      title: 'Full Document Analysis',
      value: 'full',
      checked: documentAnalysisMode === 'full',
      visible: !tempAgentRevision?.model.startsWith('img'),
      onChange:(event) => {
        const mode = event.target.checked ? event.target.value : 'none';
        handleUpdateAgentRevisionProperty(event, 'streamResponses');
        
        setTimeout(() => {
          handleSelectIntelligentEngineProperty(mode);
        }, 500);
      },
      description: 'Sometimes, it\'s necessary to send the entire content to a large language model (LLM) for processing. Typically, LLMs have a limited context window, which means not all documents can be processed at once. Full Document Analysis allows you to send the complete document, regardless of the context window size.',
      components: (
        <>
          <TextFieldStyled
            fullWidth
            label="Chunk Size"
            type="number"
            variant="outlined"
            sx={{ mt: 2, mb: 2 }}
            value={tempAgentRevision.fullDocChunkSize || ''}
            disabled={selectedAgent.sharedWithUser}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'fullDocChunkSize')}
          />
          <TextFieldStyled
            fullWidth
            label="Chunk Overlap"
            type="number"
            variant="outlined"
            sx={{ mb: 2 }}
            value={tempAgentRevision.fullDocChunkOverlap || ''}
            disabled={selectedAgent.sharedWithUser}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'fullDocChunkOverlap')}
          />
          <TextFieldStyled
            fullWidth
            label="Max Document Length (in characters)"
            type="number"
            variant="outlined"
            sx={{ mb: 2 }}
            value={tempAgentRevision.fullDocMaxLength || ''}
            disabled={selectedAgent.sharedWithUser}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'fullDocMaxLength')}
          />
        </>
      )
    },
    {
      title: 'Advanced Data Analysis',
      description: 'Advanced Data Analysis lets you upload one or more spreadsheets and treat them like a database.You can ask questions about your data, and our tool will generate and run the necessary queries to provide you with the answers.',
      checked: localAnalysisEnabled[selectedAgent.id],
      onChange: toggleLocalDataAnalysis,
    },
    {
      title: 'Privacy Filter',
      description: 'Automatically find and hide personal information, like names, addresses, or phone numbers, in a document or data set to keep it private and secures',
      checked: tempAgentRevision.scrubPii,
      disabled: selectedAgent.sharedWithUser,
      onChange: (event) => handleUpdateAgentRevisionProperty(event, 'scrubPii'),
      components:
        <>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Between 0.0 - 1.0. Lower is more sensitive.
          </Typography>
          <TextFieldStyled
            fullWidth
            label="PII Threshold"
            type="number"
            variant="outlined"
            value={tempAgentRevision.piiThreshold}
            disabled={selectedAgent.sharedWithUser}
            sx={{ mb: 2 }}
            inputProps={{
              min: 0,
              max: 1,
              step: 0.01,
            }}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'piiThreshold')}
            onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'piiThreshold', false)}
          />
      </>
    },
  ];

  return (
    <DrawerPaperStyled>
      {/* Back Button */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              if (hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) && !selectedAgent.isShared) {
                handleSaveAgent();
              }
              setTimeout(() => { openNavigationPane('agentList') }, 0);
            }}
          >
            <ArrowLeftIcon />
          </IconButton>

          <Typography variant="body2" sx={{ ml: 1 }}>
            Back
          </Typography>
        </Box>
        <TextField
          fullWidth
          variant="standard"
          value={tempAgentRevision.name}
          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'name')}
          onKeyPress={(event) => event.key === 'Enter' && handleUpdateAgentRevisionProperty(event, 'name')}
          disabled={selectedAgent.sharedWithUser}
          InputProps={{
            disableUnderline: false,
            startAdornment: (
            <InputAdornment position="start" sx={{ color: 'currentcolor' }}>
              <Typography sx={{ fontSize: '1.125rem', fontWeight: 'bold'  }}>
                Agent:
              </Typography>
            </InputAdornment>
          ),
            sx: { fontSize: '1.125rem', fontWeight: 'bold' }, // Sets font size for the input text
          }}
          InputLabelProps={{
            sx: { fontSize: '1.125rem', fontWeight: 'bold' }, // Sets font size for the label
          }}
          sx={{
            '& .MuiInput-underline:before': { borderBottom: '1px solid gray' },
            '& .MuiInput-underline:hover:before': { borderBottom: '2px solid black' },
          }}
        />
      {selectedAgent.sharedWithUser && (
        <ListItem sx={{ paddingLeft: 1, paddingRight: 0 }}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <span style={{ fontWeight: 'bold' }}>Shared By: </span>
                <span>{`${selectedAgent.ownerEmail}`}</span>
              </>
            }
          />
        </ListItem>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2} // Adjusts the gap between items if they don't fill the entire row
        sx={{ width: '100%', mt: 2 }} // Ensures the stack takes up the full width
      >
        {/* Revision Navigation */}
        <RevisionNavigationStyled>
          <IconButton
            onClick={handleSelectPreviousRevision}
            disabled={selectedAgentRevisionIndex === 0}
          >
            <ArrowLeftIcon />
          </IconButton>
          <Typography variant="body1">
            Revision #{tempAgentRevision.id}
          </Typography>
          <IconButton
            onClick={handleSelectNextRevision}
            disabled={selectedAgentRevisionIndex === selectedAgent.revisions.length - 1}
          >
            <ArrowRightIcon />
          </IconButton>
        </RevisionNavigationStyled>
        {/* Save Button */}
        <SaveButtonContainerStyled>
          <SaveButtonStyled
            variant="contained"
            color="primary"
            disabled={!hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) || selectedAgent.sharedWithUser}
            onClick={handleSaveAgent}
          >
            Save
          </SaveButtonStyled>
        </SaveButtonContainerStyled>
      </Stack>

      {/* Agent Details Form */}
      <FormStyled>
        {/* Conditional Image Dimensions */}
        {(tempAgentRevision.model === 'img-dall-e-2' || tempAgentRevision.model === 'img-dall-e-3') && (
          <Select
            variant="outlined"
            fullWidth
            value={tempAgentRevision.imageDimensions || ''}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'imageDimensions')}
            sx={{ mt: 2, mb: 2 }}
            disabled={selectedAgent.sharedWithUser}
          >
            {tempAgentRevision.model === 'img-dall-e-2' && (
              <>
                <MenuItem value="256x256">256x256</MenuItem>
                <MenuItem value="512x512">512x512</MenuItem>
                <MenuItem value="1024x1024">1024x1024</MenuItem>
              </>
            )}
            {tempAgentRevision.model === 'img-dall-e-3' && (
              <>
                <MenuItem value="1024x1024">1024x1024</MenuItem>
                <MenuItem value="1792x1024">1792x1024</MenuItem>
                <MenuItem value="1024x1792">1024x1792</MenuItem>
              </>
            )}
          </Select>
        )}

        <Box sx={{ position: 'relative', mb: 2 }}>
          {(tempAgentRevision.model === 'o1-preview' || tempAgentRevision.model === 'o1-mini' || tempAgentRevision.model === 'o1' || tempAgentRevision.model === 'o3-mini') && (
            <Typography variant="body2" color="info.main" sx={{ mb: 1 }}>
              o1 and o3 do not support custom temperature or top_p parameters.
            </Typography>
          )}
          {(tempAgentRevision.model === 'claude-3-7-sonnet-latest' && tempAgentRevision.thinkingEnabled) && (
            <Typography variant="body2" color="info.main" sx={{ mb: 1 }}>
              Claude 3.7 Sonnet extended thinking does not support custom temperature or top_p parameters.
            </Typography>
          )}
        </Box>
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setIsInteractionDesignExpanted(!isInteractionDesignExpanted)}>
            <ListItemText primary="Interaction Design" />
            {isInteractionDesignExpanted ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isInteractionDesignExpanted} timeout="auto" unmountOnExit sx={{ px: 1 }}>
          {/* Model Selection */}
          <Select
            variant="outlined"
            fullWidth
            value={tempAgentRevision.model || 'gpt-4'}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'model')}
            sx={{ mt: 2, mb: 2 }}
            MenuProps={{sx: {maxHeight: '50%'}}}
            disabled={selectedAgent.sharedWithUser}
          >
            {/* Conditional Options */}
            <MenuItem value="gpt-4o">OpenAI: GPT-4o</MenuItem>
            <MenuItem value="gpt-4o-mini">OpenAI: GPT-4o Mini</MenuItem>
            <MenuItem value="gpt-4-turbo-preview">OpenAI: GPT-4 Turbo (Preview)</MenuItem>
            <MenuItem value="gemini-2.5-pro-exp-03-25">Google: Gemini 2.5 Pro (Experimental)</MenuItem>
            <MenuItem value="gemini-2.0-flash">Google: Gemini 2.0 Flash</MenuItem>
            <MenuItem value="gemini-2.0-flash-lite">Google: Gemini 2.0 Flash Lite</MenuItem>
            <MenuItem value="gemini-1.5-pro-latest">Google: Gemini 1.5 Pro</MenuItem>
            <MenuItem value="gemini-1.5-flash">Google: Gemini 1.5 Flash</MenuItem>
            <MenuItem value="gemini-1.5-flash-8b">Google: Gemini 1.5 Flash 8B</MenuItem>
            <MenuItem value="claude-3-7-sonnet-latest">Anthropic: Claude 3.7 Sonnet</MenuItem>
            <MenuItem value="claude-3-5-sonnet-latest">Anthropic: Claude 3.5 Sonnet</MenuItem>
            <MenuItem value="claude-3-5-haiku-latest">Anthropic: Claude 3.5 Haiku</MenuItem>
            <MenuItem value="gpt-3.5-turbo">OpenAI: GPT-3.5 Turbo</MenuItem>
            <MenuItem value="gpt-4">OpenAI: GPT-4</MenuItem>
            <MenuItem value="open-mistral-7b">Mistral: 7B</MenuItem>
            <MenuItem value="open-mixtral-8x7b">Mixtral: 8x7B</MenuItem>
            <MenuItem value="open-mistral-nemo">Mistral: Nemo</MenuItem>
            <MenuItem value="mistral-small-latest">Mistral: Small</MenuItem>
            <MenuItem value="mistral-medium-latest">Mistral: Medium</MenuItem>
            <MenuItem value="mistral-large-latest">Mistral: Large 2</MenuItem>
            <MenuItem value="codestral-latest">Mistral: Codestral</MenuItem>
            <MenuItem value="sonar">Perplexity: Sonar</MenuItem>
            <MenuItem value="sonar-pro">Perplexity: Sonar Pro</MenuItem>
            <MenuItem value="sonar-reasoning">Perplexity: Sonar Reasoning</MenuItem>
            <MenuItem value="sonar-reasoning-pro">Perplexity: Sonar Reasoning Pro</MenuItem>
            <MenuItem value="grok-2-latest">xAI: Grok 2</MenuItem>
            <ListSubheader>Premium (high cost) models</ListSubheader>
            <MenuItem value="gpt-4.5-preview">OpenAI: GPT-4.5 Preview</MenuItem>
            <MenuItem value="o3-mini">OpenAI: o3-mini</MenuItem>
            <MenuItem value="o1">OpenAI: o1</MenuItem>
            <MenuItem value="o1-preview">OpenAI: o1-preview</MenuItem>
            <MenuItem value="o1-mini">OpenAI: o1-mini</MenuItem>

          </Select>
          <AlignmentDataAutocompleteEditor
            key={`alignment-data-autocomplete-editor-${autocompleteComponentKey}`}
            onChange={(text) => handleUpdateAgentRevisionProperty({ target: { value: text } }, 'masterPrompt')}
            inputText={tempAgentRevision.masterPrompt}
            placeholder={alignmentInstructionsPlaceholder}
            label={'System Instructions'}
            minLines={6}
            editable={!selectedAgent.sharedWithUser}
          />
          {/* Display note for specific models */}
          {[
            'claude-2.0',
            'claude-2.1',
            'claude-3-opus-20240229',
            'claude-3-sonnet-20240229',
            'claude-3-haiku-20240307',
            'claude-3.5-haiku-latest',
            'claude-3.5-sonnet-latest',
            'claude-instant-1.2',
            'gemini-1.0-pro',
            'gemini-1.5-pro-latest',
            'gemini-2.0-flash',
            'gemini-1.5-flash',
            'gemini-1.5-flash-8b',
            'open-mistral-7b',
            'open-mixtral-8x7b',
            'mistral-small-latest',
            'mistral-medium-latest',
            'mistral-large-latest',
            'codestral-latest'
          ].includes(tempAgentRevision.model) && (
            <ListItem sx={{ pl: 1 }}>
              <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                NOTE: Some LLMs require that the first message be from a human. We ignore assistant messages when such models are selected.
              </Typography>
            </ListItem>
          )}
          {/* Introductory Remarks */}
          <TextFieldStyled
            fullWidth
            multiline
            minRows={3}
            maxRows={15}
            label="Introductory Remarks"
            variant="outlined"
            placeholder={`Example: Hello, how can I assist you today?`}
            sx={{ mb: 2 }}
            InputLabelProps={{
              shrink: true,
              style: { transition: 'none' },
            }}
            value={tempAgentRevision.introductoryText}
            disabled={selectedAgent.sharedWithUser}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'introductoryText')}
          />
          {/* Temperature */}
          {!tempAgentRevision?.model.startsWith('img') && (
            <>
              <TextFieldStyled
                label="Temperature (0.0 - 2.0)"
                type="number"
                variant="outlined"
                value={tempAgentRevision.temperature}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'temperature')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'temperature', false)}
                fullWidth
                sx={{ mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: 2,
                  step: 0.1,
                }}
              />
              {/* top_p */}
              <TextFieldStyled
                label="top_p (0.0 - 1.0)"
                type="number"
                variant="outlined"
                value={tempAgentRevision.topP}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'topP')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'topP', false)}
                fullWidth
                sx={{ mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.1,
                }}
              />
              {/* max response tokens */}
              <TextFieldStyled
                label={`Max Response Tokens (0 - ${maxResponseTokens})`}
                type="number"
                variant="outlined"
                value={tempAgentRevision.maxTokens}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'maxTokens')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'maxTokens', false)}
                fullWidth
                sx={{ mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: maxResponseTokens,
                }}
              />
              {/* Thinking Toggle for Claude Sonnet 3-7 */}
              {tempAgentRevision.model === 'claude-3-7-sonnet-latest' && (
                <div style={{marginBottom: 2}}>
                  <AgentToolItem
                    tool={{
                      title: 'Enable Extended Thinking',
                      description: 'Extended thinking gives Claude 3.7 Sonnet enhanced reasoning capabilities for complex tasks.',
                      checked: tempAgentRevision.thinkingEnabled,
                      onChange: (event) => handleUpdateAgentRevisionProperty(event, 'thinkingEnabled'),
                      components: tempAgentRevision.thinkingEnabled && (
                        <TextFieldStyled
                          label="Thinking Budget (tokens)"
                          type="number"
                          variant="outlined"
                          value={tempAgentRevision.thinkingBudgetTokens}
                          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'thinkingBudgetTokens')}
                          onBlur={(event) => {
                            const value = parseInt(event.target.value);
                            const maxAllowed = Math.min(tempAgentRevision.maxTokens - 1, maxOutputTokensPerModel[tempAgentRevision.model] - 1);
                            if (value < 1024) {
                              event.target.value = '1024';
                            } else if (value > maxAllowed) {
                              event.target.value = maxAllowed.toString();
                            }
                            handleUpdateAgentRevisionProperty(event, 'thinkingBudgetTokens', false);
                          }}
                          fullWidth
                          sx={{ mb: 2 }}
                          disabled={selectedAgent.sharedWithUser}
                          inputProps={{
                            min: 1024,
                            max: tempAgentRevision.maxTokens - 1,
                          }}
                          helperText={`Must be between 1024 tokens and the selected Max Response Tokens (${tempAgentRevision.maxTokens} tokens)`}
                        />
                      )
                    }}
                  />
                </div>
                )}
              {/* Reasoning Effort Dropdown */}
              {agentSupportsReasoningEffort && 
                <FormControl fullWidth>  
                  <InputLabel id="reasoning-effort-label">Reasoning Effort</InputLabel>
                  <Select
                    label="Reasoning Effort"
                    value={tempAgentRevision.reasoningEffort}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'reasoningEffort', event.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  >
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                </FormControl>}

              {sonarAgent &&
                <>
                  {/* Search Domain Filter */}
                  <Box display="flex" alignItems="center" mb={2}>
                    <TextField
                      label="Search Domain Filter"
                      variant="outlined"
                      onKeyPress={handleAddDomain}
                      fullWidth
                      disabled={tempAgentRevision.searchDomainFilter.length > 2}
                      helperText={tempAgentRevision.searchDomainFilter.length > 2 ? "Maximum of 3 domains allowed" : ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {tempAgentRevision.searchDomainFilter.map((domain, index) => (
                              <Chip
                                key={index}
                                label={domain}
                                onDelete={() => handleDeleteDomain(domain)}
                                sx={{ mr: 0.5 }}
                              />
                            ))}
                          </InputAdornment>
                        )
                      }}
                    />
                    <Tooltip title="Limit the citations to up to 3 specific domains. For blacklisting add a '-' to the beginning of the domain string.">
                      <IconButton sx={{ml: -8}}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {/* Search Recency Filter */}
                  <Box display="flex" alignItems="center" sx={{mb: 2}}>
                    <FormControl fullWidth>  
                      <InputLabel id="search-recency-filter-label">Search Recency Filter</InputLabel>
                      <Select
                        label="Search Recency Filter"
                        value={tempAgentRevision.searchRecencyFilter}
                        onChange={(event) => handleUpdateAgentRevisionProperty(event, 'searchRecencyFilter', event.target.value)}
                        fullWidth
                        renderValue={
                          tempAgentRevision.searchRecencyFilter !== "" ? undefined : () => <span style={{ color: '#aaa' }}>None</span>
                        }
                      >
                        <MenuItem value={null}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="hour">Hour</MenuItem>
                        <MenuItem value="day">Day</MenuItem>
                        <MenuItem value="week">Week</MenuItem>
                        <MenuItem value="month">Month</MenuItem>
                      </Select>
                    </FormControl>
                    <Tooltip title="Returns search results within the specified time interval.">
                      <IconButton sx={{ml: -8}}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              }
            </>
          )}
        </Collapse>
        {/* Tools Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setToolsExpanded(!isToolsExpanded)}>
            <ListItemText primary="Tools" />
            {isToolsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isToolsExpanded} timeout="auto" unmountOnExit sx={{ px: 1 }}>
          <Stack direction="column" spacing={1.5} my={1.5}>
            {tools.map((tool, index) => <AgentToolItem tool={tool} key={index} />)}
          </Stack>
          {/* Streaming */}
            <FormControlLabel
              sx={{ display: 'none' }}
              control={
                <Checkbox
                  checked={tempAgentRevision.streamResponses}
                  onChange={(event) => {
                    handleUpdateAgentRevisionProperty(event, 'streamResponses')
                  }}
                  disabled={selectedAgent.sharedWithUser}
                />
              }
              label="Stream Responses (if supported)"
            />
          {!tempAgentRevision?.model.startsWith('img') && (
            <>
              {filesToLoad.length > 0 && (
                <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px' }}>
                  <List>
                    {filesToLoad.map((fileName) => {
                      // Remove the agentId prefix from the file name
                      const displayFileName = fileName.replace(`${selectedAgent.id}_`, '');

                      return (
                        <ListItem
                          key={fileName}
                          dense
                          button
                          onClick={() => handleFileSelection(fileName)}
                        >
                          <Checkbox
                            checked={(selectedLocalFilesForAnalysis[selectedAgent.id] || []).includes(fileName)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': fileName }}
                          />
                          <ListItemText primary={displayFileName} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              )}
            </>
          )}
        </Collapse>
        {/* Test Suites Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setTestSuitesExpanded(!isTestSuitesExpanded)}>
            <ListItemText primary="Test Suite" />
            {isTestSuitesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isTestSuitesExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button className={classes.nested} onClick={() => { return; }}>
              <ListItemText primary="Q&A Sheet" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Test Runs (Coming Soon)" />
            </ListItem> */}
            <ListItem button sx={{ pl: 4 }} disabled={selectedAgent.sharedWithUser} onClick={handleRatingsExportClick}>
              <ListItemText primary="Ratings Export" />
            </ListItem>
          </List>
        </Collapse>
        {/* Internal Sharing Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setIsInternalSharingExpanded(!isInternalSharingExpanded)}>
            <ListItemText primary="Internal Sharing" />
            {isInternalSharingExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isInternalSharingExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            {/* Share with Users */}
            <ListItem sx={{ pl: 4 }}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={members}
                groupBy={(option) => option.organization}
                getOptionLabel={(option) => option.email}
                value={members?.filter(member => internalShareEmails.includes(member.email))}
                limitTags={3}
                disabled={selectedAgent.sharedWithUser}
                onChange={(event, newValue) => {
                  setInternalShareEmails(newValue.map(option => option.email));
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={selected}
                      />
                      {option.email}
                    </li>
                  );
                }}
                renderGroup={(params) => {
                  return (
                    <div key={params.key}>
                      <div onClick={() => handleSelectAllMembers(params.group)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          checked={members
                            .filter(member => member.organization === params.group)
                            .every(member => internalShareEmails.includes(member.email))}
                          indeterminate={members
                            .filter(member => member.organization === params.group)
                            .some(member => internalShareEmails.includes(member.email)) && !members
                              .filter(member => member.organization === params.group)
                              .every(member => internalShareEmails.includes(member.email))}
                        />
                        <strong>{params.group}</strong>
                      </div>
                      {params.children}
                    </div>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Share with Users (Email)"
                    placeholder="Enter user emails"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
                sx={{ width: '100%' }}
              />
            </ListItem>

            {/* Share with Organizations */}
            <ListItem sx={{ pl: 4 }}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={organizationOptions}
                getOptionLabel={(option) => option.name}
                value={organizationOptions.filter(org => sharedOrganizationIds.includes(org.id))}
                onChange={handleOrganizationShareChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      checked={sharedOrganizationIds.includes(option.id)} // Check if the organization is selected
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Share with Organizations"
                    placeholder="Enter organization names"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
                sx={{ width: '100%' }}
              />
            </ListItem>

            {/* Share Button */}
            <ListItem sx={{ pl: 4 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleInternalShare}
                disabled={selectedAgent.sharedWithUser}
                sx={{ mt: 2, ml: '1rem' }}
              >
                Share
              </Button>
            </ListItem>
          </List>
        </Collapse>
        {/* Publish Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setPublishExpanded(!isPublishExpanded)}>
            <ListItemText primary="Publish" />
            {isPublishExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isPublishExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempAgentRevision.allowPublicSharing}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'allowPublicSharing')}
                    disabled={selectedAgent.sharedWithUser}
                  />
                }
                label="Public Share Link?"
              />
              {tempAgentRevision.allowPublicSharing && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Link will not be accessible until you "Save" the agent. WARNING: Saving with this option disabled will DEACTIVATE all of your publicly shared links.
                  </Typography>
                  <Typography variant="body2" component="a" href={shareUrl} sx={{ display: 'block', mb: 1, color: 'primary.main' }}>
                    {shareUrl}
                  </Typography>
                  {/* Custom URL input box */}
                  <TextFieldStyled
                    label="Custom Share Page URL"
                    placeholder='ex: my-agent-name'
                    variant="outlined"
                    fullWidth
                    value={tempAgentRevision.customShareUrl || ''}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'customShareUrl')}
                    sx={{ mb: 2 }}
                    disabled={selectedAgent.sharedWithUser}
                  />
                  {/* Button to copy link into clipboard */}
                  <Button
                    variant="contained" color="primary" size="small" onClick={() => {
                      openSnackbar('Link copied to clipboard');
                      navigator.clipboard.writeText(shareUrl);
                    }}
                    disabled={selectedAgent.sharedWithUser}
                  >
                    Copy
                  </Button>
                </Box>
              )}
            </ListItem>

            {/* <ListItem button className={classes.nested}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempAgentRevision.hasPhoneNumber}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'hasPhoneNumber')}
                  />
                }
                label="Has Phone Number?"
              />
              {tempAgentRevision.hasPhoneNumber && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Save for SMS. A2P 10DLC guidelines apply.
                  </Typography>
                  <TextFieldStyled
                    label="Phone number"
                    placeholder='Save to View'
                    variant="filled"
                    fullWidth
                    value={tempAgentRevision.phoneNumber || ''}
                    disabled
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}
            </ListItem> */}
          </List>
        </Collapse>
      </FormStyled>

      {/* Save Button at the Bottom */}
      <SaveButtonContainerStyled>
        <SaveButtonStyled
          disabled={!hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) || selectedAgent.sharedWithUser}
          variant="contained"
          color="primary"
          onClick={handleSaveAgent}
          sx={{
            padding: '10px 30px',
          }}
        >
          Save
        </SaveButtonStyled>
      </SaveButtonContainerStyled>
    </DrawerPaperStyled>
  );
}

export default AgentDetails;