import { Typography, Card, Stack, Switch, Container, Box } from "@mui/material";
import { styled } from '@mui/material/styles';

const ToolCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
}));

const AgentToolItem = ({ tool }) => {
  const { 
    title, 
    description,
    value,
    checked,
    disabled,
    visible = true,
    onChange,
    components 
  } = tool;

  if (!visible) {
    return null;
  }

  return (
    <ToolCard elevation={0}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        <Switch
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
      </Stack>
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
      {(components && checked) && (
        <Box mt={2}>
          {components}
        </Box>
      )}
    </ToolCard>
  );
};

export default AgentToolItem;
