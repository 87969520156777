// index.js
import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NotistackProvider from './components/NotistackProvider';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider } from './contexts/SettingsContext';
import { AuthProvider } from './contexts/AuthContext';
import { AgentProvider } from './contexts/AgentContext';
import { AlignmentDataProvider } from './contexts/AlignmentDataContext';
import { WorkflowProvider } from './contexts/WorkflowContext';
import { QASheetProvider } from './contexts/QASheetContext';
import { RatingsProvider } from './contexts/RatingsContext';
import { NavigationProvider } from './contexts/NavigationContext';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { ChatContextProvider } from './contexts/ChatContext';
import { SnackbarProvider } from 'src/contexts/SnackbarContext';
import { SqlContextProvider } from 'src/contexts/SqlContext';
import { RemoteStorageProvider } from 'src/contexts/RemoteStorageContext';
import SnackbarComponent from 'src/components/SnackbarComponent';

function RootApp() {
  return (
    <React.StrictMode>
      <SettingsProvider>
        <HelmetProvider context={{}}>
          <NotistackProvider>
            <NavigationProvider>
              <OrganizationProvider>
                <SnackbarProvider>
                  <RemoteStorageProvider>
                    <AuthProvider>
                      <AlignmentDataProvider>
                        <SqlContextProvider>
                          <AgentProvider>
                            <WorkflowProvider>
                              <RatingsProvider>
                                <QASheetProvider>
                                  <ChatContextProvider>
                                    <BrowserRouter>
                                      <App />
                                      <SnackbarComponent />
                                    </BrowserRouter>
                                  </ChatContextProvider>
                                </QASheetProvider>
                              </RatingsProvider>
                            </WorkflowProvider>
                          </AgentProvider>
                        </SqlContextProvider>
                      </AlignmentDataProvider>
                    </AuthProvider>
                  </RemoteStorageProvider>
                </SnackbarProvider>
              </OrganizationProvider>
            </NavigationProvider>
          </NotistackProvider>
        </HelmetProvider>
      </SettingsProvider>
    </React.StrictMode >
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootApp />);

reportWebVitals();
