import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
//import { useRemoteStorage } from 'src/contexts/RemoteStorageContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import axios from 'axios';
import routes from 'src/config/routes';
import { useAgentContext } from './AgentContext';
import { useAuth } from 'src/contexts/AuthContext';

const WorkflowContext = createContext();

const defaultWorkflowRevision = {
  id: 0,
  name: "New Collaboration",
  agentIds: []
}

const defaultWorkflow = {
  id: uuid(),
  currentRevision: 0,
  revisions: [
    defaultWorkflowRevision
  ]
}

const defaultWorkflows = [];

function WorkflowProvider({ children }) {

  const authContext = useAuth();
  const { refreshAgentsFromServer, setSelectedAgent, setSelectedAgentRevision } = useAgentContext();

  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const { openSnackbar } = useContext(SnackbarContext);
  const [workflows, setWorkflows] = useState(defaultWorkflows);
  const [selectedWorkflowRevisionIndex, setSelectedWorkflowRevisionIndex] = useState(0);
  const [selectedWorkflowRevision, setSelectedWorkflowRevision] = useState(null);

  const currentPath = window.location.pathname;
  let guid = null;

  const isRefreshingRef = useRef(false);

  if (currentPath.startsWith('/share/workflow/')) {
    guid = currentPath.replace('/share/workflow/', '');

    // remove trailing slashes
    if (guid.endsWith('/')) {
      guid = guid.substring(0, guid.length - 1);
    }
  }

  useEffect(() => {
    if (authContext?.isLoggedIn) {
      refreshWorkflowsFromServer();
    } else {
      setWorkflows(defaultWorkflows)
    }
  }, [guid, authContext?.isLoggedIn]);

  const refreshWorkflowsFromServer = async () => {
    if (isRefreshingRef.current) {
      return;
    }
    isRefreshingRef.current = true;

    // Share route
    if (guid) {

      const agents = await refreshAgentsFromServer();

      axios.get(`${routes.workflows}/${guid}`)
        .then(response => {
          //console.log('Shared workflow: ', response.data)
          setWorkflows([response.data]);

          const latestWorkflowRevision = response.data.revisions[0];
          const firstAgentId = latestWorkflowRevision.agentIds.length > 0 ? latestWorkflowRevision.agentIds[0] : null;
          const firstAgent = firstAgentId ? agents.find(agent => agent.id === firstAgentId) : null;
          const latestAgentRevision = firstAgent ? firstAgent.revisions[firstAgent.revisions.length - 1] : null;

          // // log all agent names
          // console.log('agents: ', agents.map(agent => agent.revisions[0]?.name));
          // console.log('agents: ', agents);
          // console.log('latestWorkflowRevision: ', latestWorkflowRevision);
          // console.log('firstAgentId: ', firstAgentId);
          // console.log('firstAgent: ', firstAgent);
          // console.log('latestAgentRevision: ', latestAgentRevision);


          setSelectedAgent(firstAgent);
          setSelectedAgentRevision(latestAgentRevision);

          setSelectedWorkflow(response.data)
          setSelectedWorkflowRevision(response.data.revisions[0]);
        })
        .catch(error => {
          openSnackbar("Error fetching workflow.", "error");
          console.error('Error fetching workflow: ', error);
        })
        .finally(() => {
          isRefreshingRef.current = false;
        });

      // Normal load workflows route
    } else {
      axios.get(`${routes.workflows}`)
        .then(response => {
          setWorkflows([...response.data.personalWorkflows, ...defaultWorkflows]);
        }).catch((error) => {
          openSnackbar("Error fetching collaborations.", "error");
          console.error('Error fetching collaborations: ', error);
        }).finally(() => {
          isRefreshingRef.current = false;
        });
    }
  }

  const addWorkflow = (workflow) => {
    setWorkflows(prevWorkflows => [workflow, ...prevWorkflows]);
  };

  const updateWorkflow = (updatedWorkflowRevision) => {
    setSelectedWorkflowRevision(updatedWorkflowRevision)
  };

  const saveWorkflow = async (workflow, updatedWorkflowRevision) => {
    try {
      // console.log(updatedWorkflowRevision);
      const response = await axios.put(`${routes.workflows}/${workflow.id}`, updatedWorkflowRevision);

      if (response.status === 200 && response.data && response.data.workflow) {
        setWorkflows(prevWorkflows => {
          const otherWorkflows = prevWorkflows.filter(a => a.id !== workflow.id);

          // If the workflow is new, add the revisions from the response
          const isNewWorkflow = !workflow.revisions || workflow.revisions.length === 0;

          // Otherwise, add the revisions from the response to the existing revisions
          const updatedRevisions = isNewWorkflow ?
            [...response.data.workflow.revisions] :
            [...workflow.revisions, ...response.data.workflow.revisions];

          // Update the workflow with the new revisions
          const updatedWorkflow = {
            ...updatedWorkflowRevision,
            ...response.data.workflow,
            revisions: updatedRevisions
          };

          setSelectedWorkflowRevision(updatedRevisions[updatedRevisions.length - 1]);
          setSelectedWorkflowRevisionIndex(updatedRevisions.length - 1);
          setSelectedWorkflow(updatedWorkflow);

          openSnackbar('Workflow saved successfully!', 'success');
          return [updatedWorkflow, ...otherWorkflows];
        });
      } else {
        openSnackbar('Failed to save workflow. Unexpected response.', 'error');
      }
    } catch (error) {
      console.error("Error saving workflow:", error);
      openSnackbar('Failed to save workflow. Please try again.', 'error');
    }
  };



  const handleSelectPreviousRevision = () => {
    if (selectedWorkflowRevisionIndex > 0) {
      const newIndex = selectedWorkflowRevisionIndex - 1;
      setSelectedWorkflowRevisionIndex(newIndex);
      setSelectedWorkflowRevision(selectedWorkflow.revisions[newIndex]);
    }
  };

  const handleSelectNextRevision = () => {
    if (selectedWorkflowRevisionIndex < selectedWorkflow.revisions.length - 1) {
      const newIndex = selectedWorkflowRevisionIndex + 1;
      setSelectedWorkflowRevisionIndex(newIndex);
      setSelectedWorkflowRevision(selectedWorkflow.revisions[newIndex]);
    }
  };

  const deleteWorkflow = (workflowId) => {
    setWorkflows(prevWorkflows =>
      prevWorkflows.filter(workflow => workflow.id !== workflowId)
    );
  };

  return (
    <WorkflowContext.Provider value={{
      workflows,
      addWorkflow,
      updateWorkflow,
      deleteWorkflow,
      selectedWorkflow,
      setSelectedWorkflow,
      defaultWorkflow,
      defaultWorkflowRevision,
      handleSelectNextRevision,
      handleSelectPreviousRevision,
      selectedWorkflowRevision,
      selectedWorkflowRevisionIndex,
      setSelectedWorkflowRevision,
      setSelectedWorkflowRevisionIndex,
      saveWorkflow,
      setWorkflows,
      refreshWorkflowsFromServer
    }}>
      {children}
    </WorkflowContext.Provider>
  );
}

function useWorkflowContext() {
  const context = useContext(WorkflowContext);
  if (!context) {
    throw new Error("useWorkflowContext must be used within an WorkflowProvider");
  }
  return context;
}

export { WorkflowProvider, useWorkflowContext };
